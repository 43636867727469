import { trpc } from "@api/client"
import { Box, Card } from "@atoms"
import { RecognitionType, SelectOption } from "@customTypes"
import {
  LinkButton,
  MultiSelect,
  SelectField,
  TextArea,
  TextField,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"
import { useEmployeeOptions } from "@hooks/useEmployeeOptions"
import i18n from "@i18n"
import { HorizontalBigNumber } from "@molecules"
import {
  currencyToNumberParser,
  getPercentageComparison,
  numberMaskOption,
} from "@utils"
import { FormikConfig, useFormik } from "formik"
import React from "react"
import { useTranslation } from "react-i18next"
import { generatePath } from "react-router-dom"
import { externalRoutes } from "src/routes/externalRoutes"
import { useTheme } from "styled-components"

export type RecognitionFormType = {
  employee?: { label: string; value: string }
  type?: RecognitionType
  role?: { label: string; value: string }
  departments?: { label: string; value: string }[]
  groups?: { label: string; value: string }[]
  manager?: { label: string; value: string }
  salary?: string
  justification?: string
  newRole?: { label: string; value: string }
  newDepartments?: { label: string; value: string }[]
  newGroups?: { label: string; value: string }[]
  newManager?: { label: string; value: string }
  newSalary?: string
}

export type RecognitionFormRef = ReturnType<
  typeof useFormik<RecognitionFormType>
>

const recognitionTypeFieldOptions = Object.values(RecognitionType).map(
  (value) => ({ label: i18n.t(`recognitionTypes.${value}`), value }),
)

type RecognitionFormProps = {
  validationSchema: FormikConfig<RecognitionFormType>["validationSchema"]
  initialValues?: RecognitionFormType
  validateOnChange?: boolean
  disableFields?: (keyof RecognitionFormType)[]
}

const MAX_SALARY_SUPPORTED = 999999999.99

export const RecognitionForm = React.forwardRef(
  (props: RecognitionFormProps, ref: React.Ref<RecognitionFormRef>) => {
    const { validationSchema, disableFields, initialValues, validateOnChange } =
      props

    const [employeeSearch, setEmployeeSearch] = React.useState("")
    const [newManagerSearch, setNewManagerSearch] = React.useState("")

    const theme = useTheme()
    const [t] = useTranslation("translations", {
      keyPrefix: "components.recognitionForm",
    })

    const formik = useFormik<RecognitionFormType>({
      initialValues: {
        employee: initialValues?.employee,
        type: initialValues?.type,
        role: initialValues?.role,
        departments: initialValues?.departments,
        salary: initialValues?.salary,
        justification: initialValues?.justification,
        newRole: initialValues?.newRole,
        newDepartments: initialValues?.newDepartments,
        newSalary: initialValues?.newSalary,
        groups: initialValues?.groups,
        newGroups: initialValues?.newGroups,
        manager: initialValues?.manager,
        newManager: initialValues?.newManager,
      },
      validationSchema: validationSchema,
      validateOnChange: validateOnChange,
      validateOnBlur: false,
      onSubmit: async (values): Promise<RecognitionFormType> => values,
    })

    const memoizedFormik = React.useMemo(() => formik, [formik.values])

    React.useImperativeHandle(ref, () => memoizedFormik, [memoizedFormik])

    const { data: roles = [], isFetching: isRolesLoading } =
      trpc.company.getRoles.useQuery(undefined)

    const { data: departments = [], isFetching: isDepartmentsLoading } =
      trpc.company.getDepartments.useQuery(undefined)

    const { data: groups = [], isFetching: isGroupsLoading } =
      trpc.company.getGroups.useQuery(undefined)

    const {
      employeeOptions,
      debouncedSearchTerm: debouncedEmployeeSearchTerm,
      isLoading: isEmployeeOptionsLoading,
    } = useEmployeeOptions({
      searchTerm: employeeSearch,
      initialOptions: initialValues?.employee ? [initialValues.employee] : [],
    })

    const {
      employeeOptions: newManagerOptions,
      debouncedSearchTerm: debouncedNewManagerSearchTerm,
      isLoading: isNewManagerOptionsLoading,
    } = useEmployeeOptions({
      searchTerm: newManagerSearch,
      employeeIdsToFilter: [formik.values.employee?.value ?? ""],
      initialOptions: initialValues?.newManager
        ? [initialValues.newManager]
        : [],
    })

    const handleFormChange = <K extends keyof RecognitionFormType>(
      key: K,
      value: RecognitionFormType[K],
    ) => formik.handleChange({ target: { name: key, value } })

    const getIsDisabled = (field: keyof RecognitionFormType) => {
      return disableFields?.includes(field)
    }

    const getDepartmentsOptions = (selectedDepartments: SelectOption[]) => {
      const selectedDepartmentsIds = selectedDepartments.map((it) => it.value)
      return departments
        .map(({ name, id }) => ({ label: name, value: id }))
        .filter(({ value }) => !selectedDepartmentsIds.includes(value))
    }
    const getGroupsOptions = (selectedGroups: SelectOption[]) => {
      const selectedGroupsIds = selectedGroups.map((it) => it.value)
      return groups
        .map(({ name, id }) => ({ label: name, value: id }))
        .filter(({ value }) => !selectedGroupsIds.includes(value))
    }

    const rolesOptions = roles.map(({ name, id }) => ({
      label: name,
      value: id,
    }))

    return (
      <>
        <Card gap="xs">
          <Box flexDirection="row" justifyContent="space-between">
            <Box flex={1} flexDirection="column">
              <Typography
                variant="headline8"
                variantColor={theme.colors.neutral[30]}
              >
                {t("content.basicInformationTitle")}
              </Typography>
              <Typography
                variant="body4"
                variantColor={theme.colors.neutral[50]}
              >
                {t("content.basicInformationDescription")}
              </Typography>
            </Box>

            <Box $width={"max-content"} gap="xs5" alignItems="center">
              <Typography
                variant="body3"
                variantColor={theme.colors.feedback.error[40]}
              >
                *
              </Typography>
              <Typography
                variant="body3"
                variantColor={theme.colors.neutral[30]}
              >
                {t("requiredField")}
              </Typography>
            </Box>
          </Box>

          <SelectField
            fullWidth
            required
            searchable
            value={formik.values.employee || null}
            onChange={(e) => setEmployeeSearch(e.target.value)}
            onSelectChange={(_, option: SelectOption | null) => {
              if (!option) return
              handleFormChange("employee", option)
            }}
            label={t("content.selectPersonLabel")}
            noOptionsText={
              debouncedEmployeeSearchTerm?.length < 3
                ? t("content.selectPersonNoSearchLabel")
                : isEmployeeOptionsLoading
                  ? t("selectLoadingLabel")
                  : t("selectNoOptionsLabel")
            }
            error={!!formik.errors.employee}
            helperText={formik.errors.employee}
            options={employeeOptions}
            disabled={getIsDisabled("employee")}
          />

          <SelectField
            fullWidth
            value={formik.values.type || null}
            label={<RequiredLabel label={t("content.typeRecognitionLabel")} />}
            onSelectChange={(_, { value }) => {
              handleFormChange("type", value)
            }}
            error={!!formik.errors.type}
            helperText={formik.errors.type?.toString()}
            required
            options={recognitionTypeFieldOptions}
            disabled={getIsDisabled("type")}
            searchable
          />

          <SelectField
            fullWidth
            value={formik.values.role || null}
            label={t("content.roleSelectLabel")}
            onSelectChange={(_, value) => handleFormChange("role", value)}
            noOptionsText={
              isRolesLoading
                ? t("selectLoadingLabel")
                : t("selectNoOptionsLabel")
            }
            error={!!formik.errors.role}
            helperText={formik.errors.role?.toString()}
            options={rolesOptions}
            disabled={getIsDisabled("role")}
            searchable
          />

          <MultiSelect
            fullWidth
            value={formik.values.departments ?? []}
            label={t("content.departmentSelectLabel")}
            onSelectChange={(_, value) =>
              handleFormChange("departments", value)
            }
            noOptionsText={
              isDepartmentsLoading
                ? t("selectLoadingLabel")
                : t("selectNoOptionsLabel")
            }
            error={!!formik.errors.departments}
            helperText={formik.errors.departments}
            renderInput={() => null}
            options={getDepartmentsOptions(formik.values.departments ?? [])}
            disabled={getIsDisabled("departments")}
          />

          <MultiSelect
            renderInput={() => null}
            fullWidth
            value={formik.values.groups ?? []}
            label={t("content.groupsSelectLabel")}
            onSelectChange={(_, value) => handleFormChange("groups", value)}
            noOptionsText={
              isGroupsLoading
                ? t("selectLoadingLabel")
                : t("selectNoOptionsLabel")
            }
            error={!!formik.errors.groups}
            helperText={formik.errors.groups}
            options={getGroupsOptions(formik.values.groups ?? [])}
            limitTags={3}
            disabled={getIsDisabled("groups")}
          />

          <TextField
            label={t("content.salaryLabel")}
            placeholder={t("content.salaryLabel")}
            value={formik.values.salary}
            imaskProps={numberMaskOption({ max: MAX_SALARY_SUPPORTED })}
            onChange={(e) => {
              handleFormChange("salary", e.target.value)
            }}
            error={!!formik.errors.salary}
            helperText={formik.errors.salary?.toString()}
            disabled={getIsDisabled("salary")}
          />

          <TextArea
            value={formik.values.justification}
            placeholder={t("content.justifyRecognitionLabel")}
            errorMessage={formik.errors.justification?.toString()}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
              handleFormChange("justification", e.target.value)
            }}
            required
            maxLength={1000}
            disabled={getIsDisabled("justification")}
          />
        </Card>

        <Card gap="xs">
          <Box flexDirection="row" justifyContent="space-between">
            <Box flex={1} flexDirection="column">
              <Typography
                variant="headline8"
                variantColor={theme.colors.neutral[30]}
              >
                {t("recognitionContent.title")}
              </Typography>
              <Typography
                variant="body4"
                variantColor={theme.colors.neutral[50]}
              >
                {t("recognitionContent.description")}
              </Typography>
            </Box>

            <Box $width={"max-content"} gap="xs5" alignItems="center">
              <Typography
                variant="body3"
                variantColor={theme.colors.feedback.error[40]}
              >
                *
              </Typography>
              <Typography
                variant="body3"
                variantColor={theme.colors.neutral[30]}
              >
                {t("requiredField")}
              </Typography>
            </Box>
          </Box>

          <Box flexDirection="column" gap="xs3">
            <SelectField
              fullWidth
              value={formik.values.newRole || null}
              label={t("recognitionContent.newRoleLabel")}
              onSelectChange={(_, value) => handleFormChange("newRole", value)}
              noOptionsText={
                isRolesLoading
                  ? t("selectLoadingLabel")
                  : t("selectNoOptionsLabel")
              }
              error={!!formik.errors.newRole}
              helperText={formik.errors.newRole?.toString()}
              options={rolesOptions}
              disabled={getIsDisabled("newRole")}
            />
            <div>
              <LinkButton
                variant="default"
                size="small"
                onClick={() => {
                  const path = generatePath(
                    externalRoutes.settings.createNewRole,
                  )
                  window.open(`${window.location.origin}${path}`, "_blank")
                }}
              >
                {t("recognitionContent.createNewRole")}
              </LinkButton>
            </div>
          </Box>

          <Box flexDirection="column" gap="xs3">
            <MultiSelect
              fullWidth
              value={formik.values.newDepartments ?? []}
              label={t("recognitionContent.newDepartmentLabel")}
              onSelectChange={(_, value) =>
                handleFormChange("newDepartments", value)
              }
              noOptionsText={
                isDepartmentsLoading
                  ? t("selectLoadingLabel")
                  : t("selectNoOptionsLabel")
              }
              error={!!formik.errors.newDepartments}
              helperText={formik.errors.newDepartments?.toString()}
              renderInput={() => null}
              options={getDepartmentsOptions(
                formik.values.newDepartments ?? [],
              )}
              disabled={getIsDisabled("newDepartments")}
            />
            <div>
              <LinkButton
                variant="default"
                size="small"
                onClick={() => {
                  const path = generatePath(
                    externalRoutes.settings.createNewDepartment,
                  )
                  window.open(`${window.location.origin}${path}`, "_blank")
                }}
              >
                {t("recognitionContent.createNewDepartment")}
              </LinkButton>
            </div>
          </Box>

          <Box flexDirection="column" gap="xs3">
            <MultiSelect
              renderInput={() => null}
              fullWidth
              value={formik.values.newGroups ?? []}
              label={t("recognitionContent.newGroupsLabel")}
              onSelectChange={(_, value) =>
                handleFormChange("newGroups", value)
              }
              noOptionsText={
                isGroupsLoading
                  ? t("selectLoadingLabel")
                  : t("selectNoOptionsLabel")
              }
              error={!!formik.errors.newGroups}
              helperText={formik.errors.newGroups}
              options={getGroupsOptions(formik.values.newGroups ?? [])}
              limitTags={3}
              disabled={getIsDisabled("newGroups")}
            />
            <div>
              <LinkButton
                variant="default"
                size="small"
                onClick={() => {
                  const path = generatePath(
                    externalRoutes.settings.createNewGroup,
                  )
                  window.open(`${window.location.origin}${path}`, "_blank")
                }}
              >
                {t("recognitionContent.createNewGroup")}
              </LinkButton>
            </div>
          </Box>

          <TextField
            label={t("recognitionContent.newSalaryLabel")}
            placeholder={t("recognitionContent.newSalaryLabel")}
            imaskProps={numberMaskOption({ max: MAX_SALARY_SUPPORTED })}
            value={formik.values.newSalary}
            onChange={(e) => {
              handleFormChange("newSalary", e.target.value)
            }}
            error={!!formik.errors.newSalary}
            helperText={formik.errors.newSalary?.toString()}
            disabled={getIsDisabled("newSalary")}
          />

          <SelectField
            fullWidth
            required
            searchable
            value={formik.values.newManager || null}
            onChange={(e) => setNewManagerSearch(e.target.value)}
            onSelectChange={(_, option: SelectOption | null) => {
              if (!option) return
              handleFormChange("newManager", option)
            }}
            label={t("recognitionContent.newManagerLabel")}
            noOptionsText={
              debouncedNewManagerSearchTerm?.length < 3
                ? t("recognitionContent.newManagerNoSearchLabel")
                : isNewManagerOptionsLoading
                  ? t("selectLoadingLabel")
                  : t("selectNoOptionsLabel")
            }
            error={!!formik.errors.newManager}
            helperText={formik.errors.newManager}
            options={newManagerOptions}
            disabled={getIsDisabled("newManager")}
          />

          <HorizontalBigNumber
            icon="IconCoin"
            iconColors={{
              primary: theme.colors.feedback.success[40],
              secondary: theme.colors.feedback.success[90],
            }}
            title={t("recognitionContent.bigNumberLabel")}
            textColor={theme.colors.feedback.success[40]}
            value={getPercentageComparison(
              currencyToNumberParser(formik.values.salary ?? ""),
              currencyToNumberParser(formik.values.newSalary ?? ""),
            )}
          />
        </Card>
      </>
    )
  },
)

const RequiredLabel = ({ label }: { label: string }) => {
  const theme = useTheme()
  return (
    <Box gap="xs5">
      {label}
      <Typography
        variant="body3"
        variantColor={theme.colors.feedback.error[40]}
      >
        *
      </Typography>
    </Box>
  )
}
