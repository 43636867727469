import { trpc } from "@api/client"
import i18n from "@i18n"
import { Drawer } from "@organisms"
import dispatchToast from "@utils/dispatchToast"
import React from "react"
import { useTranslation } from "react-i18next"
import { RecognitionStatus } from "server/src/types"
import { GetRecognitionType } from "."

type FooterProps = {
  recognition: GetRecognitionType
  onClose: () => void
  onApproveClick?: () => void
  onRejectClick?: () => void
}

type ButtonOptions = React.ComponentProps<typeof Drawer.Footer>["buttonList"]

export const Footer = ({
  recognition,
  onApproveClick,
  onRejectClick,
  onClose,
}: FooterProps) => {
  const utils = trpc.useUtils()
  const [t] = useTranslation("translations", {
    keyPrefix: "components.recognitionDetails.footer",
  })

  const { mutateAsync: initiate, isLoading: isInitiateLoading } =
    trpc.recognition.initiateRecognition.useMutation({
      onError: (err) => {
        dispatchToast({
          content:
            err.data?.userFriendlyError?.message ??
            i18n.t("error.internalServerError"),
          type: "error",
        })
      },
    })

  const handleApproveClick = () => onApproveClick?.()
  const handleRejectClick = () => onRejectClick?.()

  const buttons: ButtonOptions = React.useMemo(() => {
    switch (recognition.status) {
      case RecognitionStatus.InProgress:
        return [
          {
            text: t("rejectLabel"),
            variantType: "error",
            onClick: handleRejectClick,
          },
          {
            text: t("approveLabel"),
            variantType: "success",
            onClick: handleApproveClick,
          },
        ]
      case RecognitionStatus.Rejected:
      case RecognitionStatus.Approved:
        return []
      default:
        return [
          {
            text: t("startProcessLabel"),
            variantType: "default",
            onClick: async () => {
              await initiate({
                recognitionId: recognition.id,
              })
              dispatchToast({
                type: "success",
                content: t("initiateSuccessMessage"),
              })
              utils.recognition.getAllRecognitions.invalidate()
              onClose()
            },
            isLoading: isInitiateLoading,
          },
        ]
    }
  }, [recognition, isInitiateLoading])

  return buttons?.length ? <Drawer.Footer buttonList={buttons} /> : <></>
}
