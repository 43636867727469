import { Box } from "@atoms"
import { FocusedFlow } from "@organisms"
import {
  CustomItems,
  EmployeeVerticalInformation,
  RecognitionForm,
  RecognitionFormType,
  RecognitionFormRef,
} from "@templates"
import { Skeleton, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { useTranslation } from "react-i18next"
import { useTheme } from "styled-components"
import * as yup from "yup"
import i18n from "@i18n"
import { trpc } from "@api/client"
import { RecognitionType } from "server/src/types"
import { stringToCurrencyParser } from "@utils"
import dispatchToast from "@utils/dispatchToast"
import { useNavigate, useParams } from "react-router-dom"
import React from "react"
import { internalRoutes } from "src/routes/internalRoutes"

type Breadcrumb = Parameters<typeof FocusedFlow.Header>[0]["breadcrumbs"]

const breadcrumbs: Breadcrumb = [
  {
    label: i18n.t("page.edit.breadcrumbs.one"),
    route: "/recognition",
  },
  {
    label: i18n.t("page.edit.breadcrumbs.two"),
  },
]

const validationSchema = yup.object({
  employee: yup.object().required(i18n.t("page.edit.fieldRequired")),
  type: yup
    .mixed<RecognitionType>()
    .oneOf(Object.values(RecognitionType), i18n.t("page.edit.fieldRequired"))
    .required(i18n.t("page.edit.fieldRequired")),
  role: yup.object().when("type", {
    is: (value: any) =>
      [RecognitionType.Promotion, RecognitionType.Transfer].includes(value),
    then: yup.object().required(i18n.t("page.edit.fieldRequired")),
  }),
  department: yup.object().when("type", {
    is: RecognitionType.Transfer,
    then: yup.object().required(i18n.t("page.edit.fieldRequired")),
  }),
  salary: yup.number(),
  justification: yup.string().required(i18n.t("page.edit.fieldRequired")),
  newRole: yup.object().when("type", {
    is: (value: any) =>
      [RecognitionType.Promotion, RecognitionType.Transfer].includes(value),
    then: yup.object().required(i18n.t("page.edit.fieldRequired")),
  }),
  newDepartment: yup.object().when("type", {
    is: RecognitionType.Transfer,
    then: yup.object().required(i18n.t("page.edit.fieldRequired")),
  }),
  newSalary: yup
    .number()
    .typeError(i18n.t("page.edit.fieldRequired"))
    .min(1, i18n.t("page.edit.fieldRequired"))
    .required(i18n.t("page.edit.fieldRequired")),
})

function getCustomItems(values: RecognitionFormType) {
  let items: CustomItems = []
  if (values.newRole) {
    items.push({
      label: i18n.t("page.edit.employeeCustomItems.newRole"),
      icon: "IconBriefcase",
      value: values.newRole?.label ?? "Não cadastrado",
      order: 0,
    })
  }
  if (values.newDepartment) {
    items.push({
      label: i18n.t("page.edit.employeeCustomItems.newDepartment"),
      icon: "IconLayoutGrid",
      value: values.newDepartment?.label ?? "Não cadastrado",
      order: 1,
    })
  }
  if (values.newSalary) {
    items.push({
      label: i18n.t("page.edit.employeeCustomItems.newSalary"),
      icon: "IconMoneybag",
      value: stringToCurrencyParser(values.newSalary) ?? "Não cadastrado",
      order: 2,
    })
  }
  return items
}

export const EditRecognition = () => {
  const [form, setForm] = React.useState<RecognitionFormRef>()

  const theme = useTheme()
  const { id: recognitionId = "" } = useParams()
  const navigate = useNavigate()
  const [t] = useTranslation("translations", {
    keyPrefix: "page.edit",
  })

  React.useEffect(() => {
    if (!recognitionId) {
      navigate(internalRoutes.home.path, {
        replace: true,
      })
      return
    }
  }, [recognitionId])

  const { data: recognition } = trpc.recognition.getRecognitionById.useQuery(
    {
      recognitionId: recognitionId,
    },
    {
      enabled: !!recognitionId,
      cacheTime: 0,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      onError: (err) => {
        dispatchToast({
          content:
            err.data?.userFriendlyError?.message ??
            i18n.t("error.internalServerError"),
          type: "error",
        })
      },
    },
  )

  const { data: employee } = trpc.employee.getCompanyEmployee.useQuery(
    {
      employeeId: recognition?.employeeId ?? "",
    },
    {
      enabled: !!recognition,
      cacheTime: 0,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      onError: (err) => {
        dispatchToast({
          content:
            err.data?.userFriendlyError?.message ??
            i18n.t("error.internalServerError"),
          type: "error",
        })
      },
    },
  )

  const { mutateAsync: updateRecognition, isLoading: isUpdating } =
    trpc.recognition.updateRecognition.useMutation({
      onError: (err) => {
        dispatchToast({
          content:
            err.data?.userFriendlyError?.message ??
            i18n.t("error.internalServerError"),
          type: "error",
        })
      },
    })

  const handleSubmit = async () => {
    if (!form || !recognition) return
    const errors = await form.validateForm()
    if (Object.keys(errors)?.length > 0) {
      dispatchToast({
        content: t("fieldRequiredNotFilledError"),
        type: "error",
      })
      return
    }

    const values: RecognitionFormType = await form.submitForm()
    await updateRecognition({
      recognitionId: recognition?.id,
      ...values,
    })

    dispatchToast({
      content: t("recognitionEditedMessage"),
      type: "success",
    })
    navigate(internalRoutes.home.path, {
      replace: true,
    })
  }

  return (
    <FocusedFlow.Root>
      <FocusedFlow.Header breadcrumbs={breadcrumbs} />
      <FocusedFlow.Content>
        <Box flexDirection="column" $px="s" $py="m" gap="xs">
          <Typography
            variant="headline6"
            variantColor={theme.colors.neutral[20]}
          >
            {t("title")}
          </Typography>

          <Box gap="xs">
            <Box flexDirection="column" $width={"25%"} gap="xs3">
              <Typography
                variant="headline7"
                variantColor={theme.colors.secondary[50]}
              >
                {t("subtitle")}
              </Typography>
              <Typography
                variant="body3"
                variantColor={theme.colors.neutral[50]}
              >
                {t("description")}
              </Typography>
            </Box>

            <Box flexDirection="column" flex={1} gap="s">
              {employee && recognition ? (
                <RecognitionForm
                  ref={(node: RecognitionFormRef) => setForm(node)}
                  initialValues={{
                    employee: {
                      label: employee.name,
                      value: employee.id,
                    },
                    type: recognition.type,
                    department: recognition.department && {
                      label: recognition.department?.from.name,
                      value: recognition.department?.from.id,
                    },
                    role: recognition.role && {
                      label: recognition.role?.from.name,
                      value: recognition.role?.from.id,
                    },
                    justification: recognition.justification,
                    salary: recognition.salary && recognition.salary.from,
                    newSalary: recognition.salary && recognition.salary.to,
                    newDepartment: recognition.department && {
                      label: recognition.department?.to.name,
                      value: recognition.department?.to.id,
                    },
                    newRole: recognition.role && {
                      label: recognition.role?.to.name,
                      value: recognition.role?.to.id,
                    },
                  }}
                  disableFields={["employee"]}
                  validationSchema={validationSchema}
                />
              ) : (
                <Skeleton variant="rounded" height={500} width={"100%"} />
              )}
            </Box>

            <Box flexDirection="column" $width={"25%"}>
              {!!employee ? (
                <EmployeeVerticalInformation
                  employee={{
                    ...employee,
                    roles: form?.values.role
                      ? [
                          {
                            id: form?.values.role.value,
                            name: form?.values.role.label,
                            description: "",
                          },
                        ]
                      : employee.roles,
                    departments: form?.values.department
                      ? [
                          {
                            id: form?.values.department.value,
                            name: form?.values.department.label,
                            description: "",
                          },
                        ]
                      : employee.departments,
                    salary: form?.values.salary,
                  }}
                  customItems={form?.values ? getCustomItems(form.values) : []}
                />
              ) : (
                <Skeleton variant="rounded" height={500} width={"100%"} />
              )}
            </Box>
          </Box>
        </Box>
      </FocusedFlow.Content>
      <FocusedFlow.Footer
        primaryButton={{
          text: t("footer.submitButtonLabel"),
          icon: "IconCheck",
          disabled: !form?.values.type || !form?.values.employee || isUpdating,
          loading: isUpdating,
          onClick: handleSubmit,
        }}
        cancelButton={{
          text: t("footer.cancelButtonLabel"),
          onClick: () =>
            navigate(internalRoutes.home.path, {
              replace: true,
            }),
        }}
      ></FocusedFlow.Footer>
    </FocusedFlow.Root>
  )
}
