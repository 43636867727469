export function decodeJwt<T>(token: string) {
  const base64Url = token.split(".")[1]
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/")
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join(""),
  )

  return JSON.parse(jsonPayload) as T
}

export const stringToCurrencyParser = (value?: string | number): string => {
  if (!value) return ""
  const parts = value.toString().split(".")
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".")

  return "R$" + parts.join(",")
}

export const currencyToNumberParser = (currency: string): number => {
  const cleaned = currency.replace(/[R$]|[.]/g, "").replace(",", ".")
  return +cleaned
}

export const getPercentageComparison = (
  oldNumber: number,
  newNumber: number,
) => {
  if (!oldNumber || !newNumber || oldNumber === newNumber) return "0%"
  const percentageNumber = ((newNumber - oldNumber) / oldNumber) * 100
  return percentageNumber > 0
    ? `+${percentageNumber.toFixed(0)}%`
    : `${percentageNumber.toFixed(0)}%`
}
