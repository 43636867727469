import { Box } from "@atoms"
import { RecognitionType } from "@customTypes"
import {
  Accordion,
  Button,
  DatePicker,
  LinkButton,
  SelectField,
  ShapeIcon,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"
import { Trans, useTranslation } from "react-i18next"
import { useTheme } from "styled-components"
import { RecognitionUpdate, RecognitionUpdateProps } from "./RecognitionUpdate"
import { useApproveRecognition } from "./useApproveRecognition"
import { RECOGNITION_TYPE_DESCRIPTIONS } from "@utils/recognitionTypeDescriptions"
import { Modal } from "@flash-tecnologia/flamingo-ui-modal"

interface ModalApproveProps {
  isOpen?: boolean
  onApproveSuccess: () => void
  onClose: () => void
  recognitionId: string
  recognitionType: RecognitionType
  recognitionUpdates: RecognitionUpdateProps[]
}
export const ModalApprove = ({
  isOpen = false,
  onApproveSuccess,
  onClose,
  recognitionId,
  recognitionType,
  recognitionUpdates,
}: ModalApproveProps) => {
  const {
    communicationDateErrorText,
    effectiveMonthDateErrorText,
    effectiveYearDateErrorText,
    onFieldBlur,
    onFieldValueChange,
    onSubmit,
    isApproveButtonDisabled,
    isLoading,
    monthOptions,
    yearOptions,
  } = useApproveRecognition(recognitionId, onApproveSuccess)

  const [t] = useTranslation("translations", {
    keyPrefix: "components.modals.approveRecognition",
  })

  const theme = useTheme()

  return (
    <Modal.Root
      open={isOpen}
      onOpenChange={(open) => !open && onClose()}
      size="medium"
    >
      <>
        <Modal.Content.Root>
          <Modal.Content.Highlight
            size="default"
            type="announcement"
            customIconName="IconFlag"
          >
            <Typography
              variant="body3"
              weight={700}
              variantColor={theme.colors.secondary[50]}
            >
              {t("iconLabel")}
            </Typography>
          </Modal.Content.Highlight>
          <Modal.Content.Title>{t("title")}</Modal.Content.Title>
          <Modal.Content.Description>
            <Trans
              i18nKey={"components.modals.approveRecognition.description"}
              components={{ bold: <strong /> }}
            />
          </Modal.Content.Description>
          <Box flexDirection="column" gap="xs2" alignItems="center">
            {recognitionUpdates.length > 0 && (
              <Accordion
                defaultExpanded
                fullWidth
                title={RECOGNITION_TYPE_DESCRIPTIONS[recognitionType]}
                variant="default"
                leftIcon={
                  <ShapeIcon
                    variant="default"
                    name="IconSwitchHorizontal"
                    size={42}
                    stroke="default"
                  />
                }
              >
                <Box flexDirection="column" gap="xs4">
                  {recognitionUpdates.map(({ from, to, label }, idx) => (
                    <RecognitionUpdate
                      key={idx}
                      label={label}
                      from={from}
                      to={to}
                    />
                  ))}
                </Box>
              </Accordion>
            )}
            <Box $width={"100%"} flexDirection="column">
              <Box gap="xs2" justifyContent="space-between">
                <SelectField
                  fullWidth
                  onBlur={() => onFieldBlur("effectiveMonthDate")}
                  error={!!effectiveMonthDateErrorText}
                  helperText={effectiveMonthDateErrorText}
                  label={
                    <Box gap="xs5">
                      {t("form.fields.effectiveMonthDate.label")}
                      <Typography
                        variant="body3"
                        variantColor={theme.colors.feedback.error[40]}
                      >
                        *
                      </Typography>
                    </Box>
                  }
                  options={monthOptions}
                  onSelectChange={(_, { value }) =>
                    onFieldValueChange("effectiveMonthDate", value)
                  }
                />
                <SelectField
                  fullWidth
                  onBlur={() => onFieldBlur("effectiveYearDate")}
                  error={!!effectiveYearDateErrorText}
                  helperText={effectiveYearDateErrorText}
                  label={
                    <Box gap="xs5">
                      {t("form.fields.effectiveYearDate.label")}
                      <Typography
                        variant="body3"
                        variantColor={theme.colors.feedback.error[40]}
                      >
                        *
                      </Typography>
                    </Box>
                  }
                  onSelectChange={(_, { value }) =>
                    onFieldValueChange("effectiveYearDate", value)
                  }
                  options={yearOptions}
                />
              </Box>
              {!effectiveMonthDateErrorText && !effectiveYearDateErrorText && (
                <Box $pt="xs5" $pl="xs2">
                  <Typography
                    variant="body4"
                    style={localStyles.helperText}
                    weight={600}
                    variantColor={theme.colors.neutral[50]}
                  >
                    {t("form.fields.effectiveMonthDate.helperText")}
                  </Typography>
                </Box>
              )}
            </Box>
            <DatePicker
              onBlur={() => onFieldBlur("communicationDate")}
              onDateChange={(value) =>
                onFieldValueChange("communicationDate", value)
              }
              error={!!communicationDateErrorText}
              required
              label={
                <Box gap="xs5">
                  {t("form.fields.communicationDate.label")}
                  <Typography
                    variant="body3"
                    variantColor={theme.colors.feedback.error[40]}
                  >
                    *
                  </Typography>
                </Box>
              }
              helperText={
                communicationDateErrorText ||
                t("form.fields.communicationDate.helperText")
              }
            />
          </Box>
          <Modal.Content.Footer>
            <Box
              alignItems="center"
              gap="xs2"
              justifyContent="space-between"
              $width={"100%"}
            >
              <div>
                <LinkButton variant="default" onClick={onClose}>
                  {t("footer.buttons.cancel")}
                </LinkButton>
              </div>
              <Button
                variant="primary"
                loading={isLoading}
                disabled={isApproveButtonDisabled}
                onClick={() => onSubmit()}
              >
                {t("footer.buttons.approve")}
              </Button>
            </Box>
          </Modal.Content.Footer>
        </Modal.Content.Root>
      </>
    </Modal.Root>
  )
}

const localStyles = {
  helperText: {
    fontSize: "12px",
    lineHeight: "unset",
  },
}
