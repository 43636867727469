import { trpc } from "@api/client"
import { FindRecognitionById, Profile, RecognitionStatus } from "@customTypes"
import i18n from "@i18n"
import { Drawer } from "@organisms"
import dispatchToast from "@utils/dispatchToast"
import { useTranslation } from "react-i18next"
import React, { useMemo } from "react"
import { ModalRestart } from "@templates/ModalRestart"
import { ModalApprove } from "./ModalApprove"
import { ModalReject } from "./ModalReject"
import { stringToCurrencyParser } from "@utils"
import { UpdatedValueDisplayProps } from "@atoms/UpdatedValueDisplay"
import { globalContext } from "@context"
import { isEqual } from "lodash-es"

type FooterProps = {
  recognition: FindRecognitionById
  onClose: () => void
}

type FooterDataset = {
  extraContent?: JSX.Element
  buttons?: React.ComponentProps<typeof Drawer.Footer>["buttonList"]
}

type FooterByStatus = Record<RecognitionStatus, FooterDataset>
type FooterByProfile = Record<Profile, FooterByStatus>

enum ModalAvailables {
  restart = "modal-restart",
  reject = "modal-reject",
  approve = "modal-approve",
}

export const Footer = ({ recognition, onClose }: FooterProps) => {
  const [modalController, setModalController] = React.useState<
    ModalAvailables | undefined
  >()

  const utils = trpc.useUtils()
  const { profile } = React.useContext(globalContext)
  const [t] = useTranslation("translations", {
    keyPrefix: "components.recognitionDetails.footer",
  })

  const { mutateAsync: initiate, isLoading: isInitiateLoading } =
    trpc.recognition.initiateRecognition.useMutation({
      onError: (err) => {
        dispatchToast({
          content:
            err.data?.userFriendlyError?.message ??
            i18n.t("error.internalServerError"),
          type: "error",
        })
      },
    })

  const handleOpenModal = (modal: ModalAvailables) => setModalController(modal)
  const handleCloseModal = () => setModalController(undefined)

  const recognitionUpdates = useMemo(() => {
    const recognitionUpdates: UpdatedValueDisplayProps[] = []
    if (
      recognition?.role &&
      !isEqual(recognition.role.from.name, recognition.role.to.name)
    ) {
      recognitionUpdates.push({
        label: t("updates.role"),
        from: recognition.role.from.name,
        to: recognition.role.to.name,
      })
    }
    if (recognition?.departments) {
      const departmentsFrom = recognition.departments.from.map((it) => it.name)
      const departmentsTo = recognition.departments.to.map((it) => it.name)
      if (!isEqual(departmentsFrom, departmentsTo)) {
        recognitionUpdates.push({
          label: t("updates.department"),
          from: departmentsFrom.join(", "),
          to: departmentsTo.join(", "),
        })
      }
    }
    if (recognition?.groups) {
      const groupsFrom = recognition.groups.from.map((it) => it.name)
      const groupsTo = recognition.groups.to.map((it) => it.name)
      if (!isEqual(groupsFrom, groupsTo)) {
        recognitionUpdates.push({
          label: t("updates.group"),
          from: groupsFrom.join(", "),
          to: groupsTo.join(", "),
        })
      }
    }
    if (
      recognition?.manager?.to &&
      !isEqual(recognition.manager.from?.name, recognition.manager.to.name)
    ) {
      recognitionUpdates.push({
        label: t("updates.manager"),
        from: recognition.manager.from?.name,
        to: recognition.manager.to.name,
      })
    }
    if (recognition?.salary) {
      const salaryFrom = stringToCurrencyParser(recognition.salary.from)
      const salaryTo = stringToCurrencyParser(recognition.salary.to)
      if (!isEqual(salaryFrom, salaryTo))
        recognitionUpdates.push({
          label: t("updates.salary"),
          from: salaryFrom,
          to: salaryTo,
        })
    }

    return recognitionUpdates
  }, [recognition])

  const datasetByProfile: FooterByProfile = React.useMemo(() => {
    return {
      [Profile.admin]: {
        [RecognitionStatus.InProgress]: {
          extraContent: (
            <>
              <ModalApprove
                isOpen={modalController === ModalAvailables.approve}
                onClose={handleCloseModal}
                recognitionId={recognition.id}
                recognitionType={recognition.type}
                recognitionUpdates={recognitionUpdates}
                onApproveSuccess={() => handleCloseModal()}
              />
              <ModalReject
                isOpen={modalController === ModalAvailables.reject}
                onClose={handleCloseModal}
                recognitionId={recognition.id}
                onRejectSuccess={() => handleCloseModal()}
              />
            </>
          ),
          buttons: [
            {
              text: t("rejectLabel"),
              variantType: "error",
              onClick: () => handleOpenModal(ModalAvailables.reject),
            },
            {
              text: t("approveLabel"),
              variantType: "success",
              onClick: () => handleOpenModal(ModalAvailables.approve),
            },
          ],
        },
        [RecognitionStatus.Rejected]: {
          extraContent: (
            <ModalRestart
              isOpen={modalController === ModalAvailables.restart}
              onClose={handleCloseModal}
              recognitionId={recognition.id}
            />
          ),
          buttons: [
            {
              text: t("restartLabel"),
              variantType: "default",
              onClick: () => handleOpenModal(ModalAvailables.restart),
            },
          ],
        },
        [RecognitionStatus.Approved]: {},
        [RecognitionStatus.Canceled]: {},
        [RecognitionStatus.NotStarted]: {
          buttons: [
            {
              text: t("startProcessLabel"),
              variantType: "default",
              onClick: async () => {
                await initiate({
                  recognitionId: recognition.id,
                })
                dispatchToast({
                  type: "success",
                  content: t("initiateSuccessMessage"),
                })
                utils.recognition.invalidate()
                onClose()
              },
              isLoading: isInitiateLoading,
            },
          ],
        },
      },
      [Profile.manager]: {
        [RecognitionStatus.Approved]: {},
        [RecognitionStatus.Canceled]: {},
        [RecognitionStatus.InProgress]: {},
        [RecognitionStatus.NotStarted]: {},
        [RecognitionStatus.Rejected]: {},
      },
    }
  }, [modalController, recognition, isInitiateLoading])

  const dataset = datasetByProfile[profile][recognition.status]

  return dataset.buttons?.length ? (
    <>
      {!!dataset.extraContent && dataset.extraContent}
      <Drawer.Footer buttonList={dataset.buttons} />
    </>
  ) : (
    <></>
  )
}
