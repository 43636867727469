import { Box } from "@atoms"
import { Icons } from "@flash-tecnologia/hros-web-ui-v2"
import { RecognitionFromStyled, RecognitionToStyled } from "./styles"
import { useTheme } from "styled-components"

export interface RecognitionUpdateProps {
  from: string
  to: string
}

export const RecognitionUpdate = ({ from, to }: RecognitionUpdateProps) => {
  const theme = useTheme()
  return (
    <Box gap="xs2">
      <RecognitionFromStyled variant="body3">{from}</RecognitionFromStyled>
      <Icons
        name="IconArrowRight"
        fill="transparent"
        color={theme.colors.neutral[50]}
      />
      <RecognitionToStyled variant="body3">{to}</RecognitionToStyled>
    </Box>
  )
}
