import { trpc } from "@api/client"
import i18n from "@i18n"
import dispatchToast from "@utils/dispatchToast"
import { useFormik } from "formik"
import { useTranslation } from "react-i18next"
import * as yup from "yup"

const validationSchema = yup.object({
  rejectReason: yup
    .string()
    .required(
      i18n.t(
        "components.modals.rejectRecognition.form.fields.rejectReason.validation.required",
      ),
    ),
})
export function useRejectRecognition(
  recognitionId: string,
  onRejectSuccess: () => void,
) {
  const [t] = useTranslation("translations", {
    keyPrefix: "components.modals.rejectRecognition",
  })

  const queryClient = trpc.useUtils()

  const { mutateAsync: rejectRecognition, isLoading } =
    trpc.recognition.rejectRecognition.useMutation({
      onError: (err) => {
        dispatchToast({
          content:
            err.data?.userFriendlyError?.message ?? t("messages.rejectFailure"),
          type: "error",
        })
      },
      onSuccess: () => {
        queryClient.recognition.getAllRecognitions.invalidate()
        dispatchToast({
          content: t("messages.rejectSuccess.content"),
          type: "success",
        })
        onRejectSuccess()
      },
    })

  const rejectRecognitionFormik = useFormik<{ rejectReason: string }>({
    initialValues: {
      rejectReason: "",
    },
    validationSchema,
    onSubmit: ({ rejectReason }) => {
      if (!rejectReason) {
        dispatchToast({
          content: t("messages.missingFields"),
          type: "error",
        })
        return
      }
      rejectRecognition({
        recognitionId,
        rejectReason,
      })
    },
    validateOnBlur: true,
  })

  const rejectReasonErrorText =
    (rejectRecognitionFormik.touched.rejectReason &&
      rejectRecognitionFormik.errors.rejectReason) ||
    ""

  const handleRejectReasonChange = (value: any) => {
    rejectRecognitionFormik.handleChange({
      target: { name: "rejectReason", value },
    })
  }

  const isRejectButtonDisabled = !rejectRecognitionFormik.values.rejectReason

  const handleRejectReasonBlur = () => {
    rejectRecognitionFormik.setFieldTouched("rejectReason")
  }

  return {
    isLoading,
    isRejectButtonDisabled,
    rejectReasonErrorText,
    onRejectReasonBlur: handleRejectReasonBlur,
    onRejectReasonChange: handleRejectReasonChange,
    onSubmit: rejectRecognitionFormik.handleSubmit,
  }
}
