import { RecognitionType } from "@customTypes"
import i18n from "@i18n"

export const RECOGNITION_TYPE_DESCRIPTIONS: Record<RecognitionType, string> = {
  [RecognitionType.PositionChange]: i18n.t(
    `recognitionTypes.${RecognitionType.PositionChange}`,
  ),
  [RecognitionType.RoleChange]: i18n.t(
    `recognitionTypes.${RecognitionType.RoleChange}`,
  ),
  [RecognitionType.Effectivation]: i18n.t(
    `recognitionTypes.${RecognitionType.Effectivation}`,
  ),
  [RecognitionType.FrameworkAdjustment]: i18n.t(
    `recognitionTypes.${RecognitionType.FrameworkAdjustment}`,
  ),
  [RecognitionType.SalaryEqualization]: i18n.t(
    `recognitionTypes.${RecognitionType.SalaryEqualization}`,
  ),
  [RecognitionType.Promotion]: i18n.t(
    `recognitionTypes.${RecognitionType.Promotion}`,
  ),
  [RecognitionType.Merit]: i18n.t(`recognitionTypes.${RecognitionType.Merit}`),
  [RecognitionType.Transfer]: i18n.t(
    `recognitionTypes.${RecognitionType.Transfer}`,
  ),
  [RecognitionType.SalaryChange]: i18n.t(
    `recognitionTypes.${RecognitionType.SalaryChange}`,
  ),
}
