export enum RecognitionStatus {
  NotStarted = "not-started",
  InProgress = "in-progress",
  Approved = "approved",
  Rejected = "rejected",
  Canceled = "canceled",
}

export enum RecognitionType {
  PositionChange = "position-change",
  RoleChange = "role-change",
  Effectivation = "effectivation",
  FrameworkAdjustment = "framework-adjustment",
  SalaryEqualization = "salary-equalization",
  Promotion = "promotion",
  Merit = "merit",
  Transfer = "transfer",
  SalaryChange = "salary-change",
}

export interface Role {
  from: { id: string; name: string }
  to: { id: string; name: string }
}

export interface Departments {
  from: { id: string; name: string }[]
  to: { id: string; name: string }[]
}

export interface Groups {
  from: { id: string; name: string }[]
  to: { id: string; name: string }[]
}

export interface Manager {
  from: { id: string; name: string } | null
  to: { id: string; name: string }
}

export interface Salary {
  from?: number
  to?: number
}

export type Comment = {
  message: string
  authorEmployeeId: string
  sentDate: Date
  timeSince: string
  author: Person
}

export type Person = {
  id: string
  name: string
  profilePicture?: string
}

export interface RecognitionEntity {
  id: string
  employeeId: string
  companyId: string
  status: RecognitionStatus
  type: RecognitionType
  justification?: string
  updatedBy: string
  rejectReason?: string
  effectiveDate?: string
  communicationDate?: string
  role?: Role
  departments?: Departments
  groups?: Groups
  manager?: Manager
  salary?: Salary
  comments?: Comment[]
  createdAt: Date
  updatedAt: Date
  requestedBy: string
}

export interface FindRecognition extends Omit<RecognitionEntity, "manager"> {
  employee: Person
  manager?: Person
}

export interface FindRecognitionById
  extends Omit<RecognitionEntity, "manager" | "requestedBy"> {
  employee: Person
  requestedBy?: Person
  manager?: {
    from: Person | null
    to?: { id: string; name: string }
  }
}

interface Change {
  key: string
  oldValue: string | number | Date | boolean
  newValue: string | number | Date | boolean
  label: string
}

export interface RecognitionHistory {
  changes: Change[]
  updatedBy: Person
  action: string
  updatedAt: string
}
