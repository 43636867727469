export function decodeJwt<T>(token: string) {
  const base64Url = token.split(".")[1]
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/")
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join(""),
  )

  return JSON.parse(jsonPayload) as T
}

export const stringToCurrencyParser = (value?: number): string => {
  if (!value) return "R$ 0,00"
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(value)
}

export const currencyToNumberParser = (string: string): number => {
  if (!string) return 0
  const numericString = string
    .replace("R$", "")
    .trim()
    .replace(/\./g, "")
    .replace(",", ".")

  return Number(numericString)
}

export const getPercentageComparison = (
  oldNumber?: number,
  newNumber?: number,
) => {
  if (!oldNumber || !newNumber || oldNumber === newNumber) return "0%"
  const percentageNumber = ((newNumber - oldNumber) / oldNumber) * 100
  return percentageNumber > 0
    ? `+${percentageNumber.toFixed(0)}%`
    : `${percentageNumber.toFixed(0)}%`
}

export const numberMaskOption = ({ max }: { max?: number }) => {
  return {
    mask: "R$ num",
    blocks: {
      num: {
        mask: Number,
        thousandsSeparator: ".",
        radix: ",",
        scale: 2,
        signed: false,
        normalizeZeros: true,
        padFractionalZeros: true,
        max,
      },
    },
  }
}
