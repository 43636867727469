import { Box } from "@atoms"
import { FocusedFlow } from "@organisms"
import {
  CustomItems,
  EmployeeVerticalInformation,
  RecognitionForm,
  RecognitionFormType,
  RecognitionFormRef,
} from "@templates"
import { Skeleton, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { useTranslation } from "react-i18next"
import { useTheme } from "styled-components"
import * as yup from "yup"
import i18n from "@i18n"
import { trpc } from "@api/client"
import { RecognitionType } from "server/src/types"
import { currencyToNumberParser } from "@utils"
import dispatchToast from "@utils/dispatchToast"
import { useNavigate, useParams } from "react-router-dom"
import React from "react"
import { internalRoutes } from "src/routes/internalRoutes"

type Breadcrumb = Parameters<typeof FocusedFlow.Header>[0]["breadcrumbs"]

const breadcrumbs: Breadcrumb = [
  {
    label: i18n.t("page.edit.breadcrumbs.one"),
    route: "/recognition",
  },
  {
    label: i18n.t("page.edit.breadcrumbs.two"),
  },
]

const fieldRequired = i18n.t("page.edit.fieldRequired")

const validationSchema = yup.object({
  employee: yup.object().required(fieldRequired),
  type: yup
    .mixed<RecognitionType>()
    .oneOf(Object.values(RecognitionType), fieldRequired)
    .required(fieldRequired),
  role: yup.object(),
  department: yup.object(),
  groups: yup.array(),
  salary: yup.string(),
  justification: yup.string().required(fieldRequired),
  newRole: yup.object(),
  newDepartment: yup.object(),
  newManager: yup.object(),
  newGroups: yup.array(),
  newSalary: yup.string(),
})

const notInformed = i18n.t("page.edit.employeeCustomItems.notInformed")

function getCustomItems(values: RecognitionFormType) {
  let items: CustomItems = []
  if (values.newRole) {
    items.push({
      label: i18n.t("page.edit.employeeCustomItems.newRole"),
      icon: "IconBriefcase",
      value: values.newRole?.label ?? notInformed,
      order: 0,
    })
  }
  if (values.newDepartments) {
    items.push({
      label: i18n.t("page.edit.employeeCustomItems.newDepartment"),
      icon: "IconLayoutGrid",
      value:
        values.newDepartments.map(({ label }) => label).join(", ") ??
        notInformed,
      order: 1,
    })
  }
  if (values.newGroups?.length) {
    items.push({
      label: i18n.t("page.edit.employeeCustomItems.newGroups"),
      icon: "IconUsers",
      value: values.newGroups
        ? values.newGroups.map(({ label }) => label).join(", ")
        : notInformed,
      order: 2,
    })
  }
  if (values.newSalary) {
    items.push({
      label: i18n.t("page.edit.employeeCustomItems.newSalary"),
      icon: "IconCoin",
      value: values.newSalary ?? notInformed,
      order: 3,
    })
  }
  if (values.newManager) {
    items.push({
      label: i18n.t("page.edit.employeeCustomItems.newManager"),
      icon: "IconUserPlus",
      value: values.newManager.label ?? notInformed,
      order: 4,
    })
  }
  return items
}

export const EditRecognition = () => {
  const [form, setForm] = React.useState<RecognitionFormRef>()

  const theme = useTheme()
  const { id: recognitionId = "" } = useParams()
  const navigate = useNavigate()
  const [t] = useTranslation("translations", {
    keyPrefix: "page.edit",
  })

  React.useEffect(() => {
    if (!recognitionId) {
      navigate(internalRoutes.home.path, {
        replace: true,
      })
      return
    }
  }, [recognitionId])

  const { data: recognition } = trpc.recognition.getRecognitionById.useQuery(
    {
      recognitionId: recognitionId,
    },
    {
      enabled: !!recognitionId,
      cacheTime: 0,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      onError: (err) => {
        dispatchToast({
          content:
            err.data?.userFriendlyError?.message ??
            i18n.t("error.internalServerError"),
          type: "error",
        })
      },
    },
  )

  const { data: employee } = trpc.employee.getCompanyEmployee.useQuery(
    {
      employeeId: recognition?.employeeId ?? "",
    },
    {
      enabled: !!recognition,
      cacheTime: 0,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      onError: (err) => {
        dispatchToast({
          content:
            err.data?.userFriendlyError?.message ??
            i18n.t("error.internalServerError"),
          type: "error",
        })
      },
    },
  )

  const { mutateAsync: updateRecognition, isLoading: isUpdating } =
    trpc.recognition.updateRecognition.useMutation({
      onError: (err) => {
        dispatchToast({
          content:
            err.data?.userFriendlyError?.message ??
            i18n.t("error.internalServerError"),
          type: "error",
        })
      },
    })

  const handleSubmit = async () => {
    if (!form || !recognition) return
    const errors = await form.validateForm()
    if (Object.keys(errors)?.length > 0) {
      dispatchToast({
        content: t("fieldRequiredNotFilledError"),
        type: "error",
      })
      return
    }

    const values: RecognitionFormType = await form.submitForm()
    await updateRecognition({
      recognitionId: recognition?.id,
      justification: values.justification ?? undefined,
      type: values.type ?? undefined,
      salary: values.salary ? currencyToNumberParser(values.salary) : undefined,
      newSalary: values.newSalary
        ? currencyToNumberParser(values.newSalary)
        : undefined,
      role: values.role ?? undefined,
      newRole: values.newRole ?? undefined,
      departments: values.departments ?? undefined,
      newDepartments: values.newDepartments ?? undefined,
      groups: values.groups ?? undefined,
      newGroups: values.newGroups ?? undefined,
      manager: values.manager ?? undefined,
      newManager: values.newManager ?? undefined,
    })

    dispatchToast({
      content: t("recognitionEditedMessage"),
      type: "success",
    })
    navigate(internalRoutes.home.path, {
      replace: true,
    })
  }

  return (
    <FocusedFlow.Root>
      <FocusedFlow.Header breadcrumbs={breadcrumbs} />
      <FocusedFlow.Content>
        <Box flexDirection="column" $px="s" $py="m" gap="xs">
          <Typography
            variant="headline6"
            variantColor={theme.colors.neutral[20]}
          >
            {t("title")}
          </Typography>

          <Box gap="xs">
            <Box flexDirection="column" $width={"25%"} gap="xs3">
              <Typography
                variant="headline7"
                variantColor={theme.colors.secondary[50]}
              >
                {t("subtitle")}
              </Typography>
              <Typography
                variant="body3"
                variantColor={theme.colors.neutral[50]}
              >
                {t("description")}
              </Typography>
            </Box>

            <Box flexDirection="column" flex={1} gap="s">
              {employee && recognition ? (
                <RecognitionForm
                  ref={(node: RecognitionFormRef) => setForm(node)}
                  initialValues={{
                    employee: {
                      label: employee.name,
                      value: employee.id,
                    },
                    type: recognition.type,
                    departments: recognition.departments?.from.map(
                      (department) => ({
                        label: department.name,
                        value: department.id,
                      }),
                    ),
                    newDepartments: recognition.departments?.to.map(
                      (department) => ({
                        label: department.name,
                        value: department.id,
                      }),
                    ),
                    role: recognition.role?.from && {
                      label: recognition.role.from.name,
                      value: recognition.role.from.id,
                    },
                    newRole: recognition.role?.to && {
                      label: recognition.role.to.name,
                      value: recognition.role.to.id,
                    },
                    justification: recognition.justification,
                    salary: recognition.salary?.from
                      ? Intl.NumberFormat("pt-BR").format(
                          recognition.salary.from,
                        )
                      : undefined,
                    newSalary: recognition.salary?.to
                      ? Intl.NumberFormat("pt-BR").format(recognition.salary.to)
                      : undefined,
                    groups: recognition.groups?.from.map((group) => ({
                      label: group.name,
                      value: group.id,
                    })),
                    newGroups: recognition.groups?.to.map((group) => ({
                      label: group.name,
                      value: group.id,
                    })),
                    manager: recognition.manager?.from
                      ? {
                          label: recognition.manager.from.name,
                          value: recognition.manager.from.id,
                        }
                      : undefined,
                    newManager: recognition.manager?.to && {
                      label: recognition.manager.to.name,
                      value: recognition.manager.to.id,
                    },
                  }}
                  disableFields={["employee"]}
                  validationSchema={validationSchema}
                />
              ) : (
                <Skeleton variant="rounded" height={500} width={"100%"} />
              )}
            </Box>

            <Box flexDirection="column" $width={"25%"}>
              {!!employee ? (
                <EmployeeVerticalInformation
                  employee={{
                    ...employee,
                    roles: form?.values.role
                      ? [
                          {
                            id: form?.values.role.value,
                            name: form?.values.role.label,
                            description: "",
                          },
                        ]
                      : employee.roles,
                    departments: form?.values.departments
                      ? form.values.departments.map(({ label, value }) => ({
                          id: value,
                          name: label,
                          description: "",
                        }))
                      : employee.departments,
                    groups: form?.values.groups
                      ? form.values.groups.map(({ label, value }) => ({
                          id: value,
                          name: label,
                        }))
                      : employee.groups,
                    salary: currencyToNumberParser(form?.values.salary ?? ""),
                  }}
                  customItems={form?.values ? getCustomItems(form.values) : []}
                />
              ) : (
                <Skeleton variant="rounded" height={500} width={"100%"} />
              )}
            </Box>
          </Box>
        </Box>
      </FocusedFlow.Content>
      <FocusedFlow.Footer
        primaryButton={{
          text: t("footer.submitButtonLabel"),
          icon: "IconCheck",
          disabled: !form?.values.type || !form?.values.employee || isUpdating,
          loading: isUpdating,
          onClick: handleSubmit,
        }}
        cancelButton={{
          text: t("footer.cancelButtonLabel"),
          onClick: () =>
            navigate(internalRoutes.home.path, {
              replace: true,
            }),
        }}
      ></FocusedFlow.Footer>
    </FocusedFlow.Root>
  )
}
