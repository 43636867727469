import { trpc } from "@api/client"
import { globalContext } from "@context"
import { Profile, SelectOption } from "@customTypes"
import { useDelayFn } from "@hooks"
import React, { useEffect, useState } from "react"

type UseEmployeeOptionsParams = {
  searchTerm: string
  employeeIdsToFilter?: string[]
  initialOptions?: SelectOption[]
}

export const useEmployeeOptions = ({
  searchTerm,
  employeeIdsToFilter,
  initialOptions = [],
}: UseEmployeeOptionsParams) => {
  const [debouncedValue, setDebouncedValue] = useState(searchTerm)

  const [employeeOptions, setEmployeeOptions] =
    useState<SelectOption[]>(initialOptions)

  const delayFn = useDelayFn()

  useEffect(() => {
    delayFn(() => setDebouncedValue(searchTerm))
  }, [searchTerm])

  const { profile, user } = React.useContext(globalContext)

  const { data: employeeList, isFetching } =
    trpc.employee.getCompanyEmployees.useQuery(
      {
        search: debouncedValue,
        managerId: profile === Profile.manager ? user.id : undefined,
      },
      { enabled: debouncedValue.length > 2 },
    )

  useEffect(() => {
    if (debouncedValue) {
      setEmployeeOptions(
        employeeList
          ?.filter(({ id }) => !employeeIdsToFilter?.includes(id))
          ?.map(({ name, id }) => ({ label: name, value: id })) ?? [],
      )
    }
  }, [employeeList, debouncedValue])

  return {
    employeeList,
    employeeOptions,
    setEmployeeOptions,
    isLoading: isFetching,
    debouncedSearchTerm: debouncedValue,
  }
}
