import React from "react"
import { Box } from "@atoms"
import { PillButton, Tag, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { useTheme } from "styled-components"
import { Grid, PinkSkeleton } from "./styles"

type TagType = Pick<React.ComponentProps<typeof Tag>, "variant"> & {
  text: string
}

type Content = {
  title: string
  value: React.ReactNode
}

export type HeaderProps = {
  title: string
  tags: TagType[]
  contents: Content[]
  loading?: boolean
  onClose: () => void
}

export const Header = ({
  title,
  tags = [],
  contents,
  loading = false,
  onClose,
}: HeaderProps) => {
  const theme = useTheme()

  return (
    <Box
      flexDirection="column"
      $backgroundColor={theme.colors.secondary[99]}
      $px="m"
      $py="m"
      gap="xs"
    >
      <Box
        flexDirection="row"
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <Typography variant="headline7" variantColor={theme.colors.neutral[10]}>
          {title}
        </Typography>

        <PillButton
          icon="IconX"
          size="small"
          variant="default"
          onClick={onClose}
        />
      </Box>

      {loading ? (
        <PinkSkeleton variant="rounded" width={100} height={30} />
      ) : (
        <Box>
          {tags?.map((tagProps, idx) => (
            <Tag
              key={`tag_${tagProps.text}_${idx}`}
              variant={tagProps.variant}
              as="span"
            >
              {tagProps.text}
            </Tag>
          ))}
        </Box>
      )}

      <Grid itemsQty={contents.length}>
        {contents.map((content, idx) =>
          loading ? (
            <PinkSkeleton
              key={`skeleton_${content.title}_${idx}`}
              variant="rounded"
              height={60}
            />
          ) : (
            <Box
              key={`box_${content.title}_${idx}`}
              flexDirection="column"
              gap="xs4"
            >
              <Typography
                variant="body4"
                variantColor={theme.colors.neutral[30]}
              >
                {content.title}
              </Typography>

              {content.value}
            </Box>
          ),
        )}
      </Grid>
    </Box>
  )
}
