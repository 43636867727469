import React, { ReactElement } from "react"
import {
  getAccessToken,
  usePermissions,
  useSelectedCompany,
} from "@flash-tecnologia/hros-web-utility"
import { decodeJwt } from "@utils"

interface JwtPayload {
  employeeId: string
  economicGroupId: string
}

export interface IGlobalContext {
  employeeId?: string
  companyId?: string
  economicGroupId?: string
  companyPermissions?: string[]
}

const globalContext = React.createContext<IGlobalContext>({} as IGlobalContext)

const GlobalContext = (props: { children: ReactElement }) => {
  const [tokenData, setTokenData] = React.useState<JwtPayload>()
  const { selectedCompany } = useSelectedCompany()
  const allPermissions = usePermissions()

  const permissions = React.useMemo(() => {
    const companyPermissions = allPermissions?.companies?.find(
      (company) => company.id === selectedCompany.id,
    )
    return companyPermissions?.permissions
  }, [allPermissions, selectedCompany.id])

  React.useEffect(() => {
    async function decodeAccessToken() {
      const token = await getAccessToken()
      if (!token) throw new Error("NOT AUTHENTICATED")

      const decodedToken = decodeJwt<JwtPayload>(token)
      setTokenData(decodedToken)
    }

    decodeAccessToken()
  }, [selectedCompany])

  return (
    <globalContext.Provider
      value={{
        ...tokenData,
        companyPermissions: permissions,
      }}
    >
      {props.children}
    </globalContext.Provider>
  )
}

export { GlobalContext, globalContext }
