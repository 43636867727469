import { Box } from "@atoms"
import { RecognitionType } from "@customTypes"
import {
  Accordion,
  Button,
  DatePicker,
  dayjs,
  LinkButton,
  Modal,
  SelectField,
  ShapeIcon,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"
import { Trans, useTranslation } from "react-i18next"
import { useTheme } from "styled-components"
import { RecognitionUpdate, RecognitionUpdateProps } from "./RecognitionUpdate"
import {
  AccordionIconStyled,
  HelperTextSyled,
  StyledModalContent,
  TextStyled,
} from "./styles"
import { useApproveRecognition } from "./useApproveRecognition"

interface ModalApproveProps {
  isOpen?: boolean
  onApproveSuccess: () => void
  onClose: () => void
  recognitionId: string
  recognitionType: RecognitionType
  recognitionUpdates: RecognitionUpdateProps[]
}
export const ModalApprove = ({
  isOpen = false,
  onApproveSuccess,
  onClose,
  recognitionId,
  recognitionType,
  recognitionUpdates,
}: ModalApproveProps) => {
  const {
    communicationDateErrorText,
    effectiveMonthDateErrorText,
    effectiveYearDateErrorText,
    onFieldBlur,
    onFieldValueChange,
    onSubmit,
    isApproveButtonDisabled,
    isLoading,
    monthOptions,
    typeDescriptions,
    yearOptions,
  } = useApproveRecognition(recognitionId, onApproveSuccess)

  const [t] = useTranslation("translations", {
    keyPrefix: "components.modals.approveRecognition",
  })

  const theme = useTheme()

  return (
    <Modal.Root open={isOpen} onClose={onClose} size="xs">
      <>
        <StyledModalContent>
          <Box flexDirection="column" gap="xs2" alignItems="center">
            <ShapeIcon
              name={"IconFlag"}
              size={64}
              color={theme.colors.secondary[50]}
              variant={"default"}
            />
            <Typography
              variant="body3"
              weight={700}
              variantColor={theme.colors.secondary[50]}
            >
              {t("iconLabel")}
            </Typography>
            <TextStyled
              variant="headline6"
              weight={700}
              variantColor={theme.colors.neutral[20]}
            >
              {t("title")}
            </TextStyled>
            <TextStyled
              variant="body3"
              weight={400}
              variantColor={theme.colors.neutral[20]}
            >
              <Trans
                i18nKey={"components.modals.approveRecognition.description"}
                components={{
                  bold: <strong />,
                }}
              />
            </TextStyled>
            <Accordion
              defaultExpanded
              fullWidth
              title={typeDescriptions[recognitionType]}
              variant="default"
              leftIcon={
                <AccordionIconStyled
                  variant="default"
                  name="IconSwitchHorizontal"
                  size={42}
                  stroke="default"
                />
              }
            >
              <Box flexDirection="column" gap="xs1">
                {recognitionUpdates.map(({ from, to }, index) => (
                  <RecognitionUpdate key={index} from={from} to={to} />
                ))}
              </Box>
            </Accordion>
            <Box $width={"100%"} flexDirection="column">
              <Box gap="xs2" justifyContent="space-between">
                <SelectField
                  fullWidth
                  onBlur={() => onFieldBlur("effectiveMonthDate")}
                  error={!!effectiveMonthDateErrorText}
                  helperText={effectiveMonthDateErrorText}
                  label={
                    <Box gap="xs5">
                      {t("form.fields.effectiveMonthDate.label")}
                      <Typography
                        variant="body3"
                        variantColor={theme.colors.feedback.error[40]}
                      >
                        *
                      </Typography>
                    </Box>
                  }
                  options={monthOptions}
                  onSelectChange={(_, { value }) =>
                    onFieldValueChange("effectiveMonthDate", value)
                  }
                />
                <SelectField
                  fullWidth
                  onBlur={() => onFieldBlur("effectiveYearDate")}
                  error={!!effectiveYearDateErrorText}
                  helperText={effectiveYearDateErrorText}
                  label={
                    <Box gap="xs5">
                      {t("form.fields.effectiveYearDate.label")}
                      <Typography
                        variant="body3"
                        variantColor={theme.colors.feedback.error[40]}
                      >
                        *
                      </Typography>
                    </Box>
                  }
                  onSelectChange={(_, { value }) =>
                    onFieldValueChange("effectiveYearDate", value)
                  }
                  options={yearOptions}
                />
              </Box>
              {!effectiveMonthDateErrorText && !effectiveYearDateErrorText && (
                <HelperTextSyled
                  variant="body4"
                  style={localStyles.helperText}
                  weight={600}
                  variantColor={theme.colors.neutral[50]}
                >
                  {t("form.fields.effectiveMonthDate.helperText")}
                </HelperTextSyled>
              )}
            </Box>
            <DatePicker
              fromDate={dayjs()}
              onBlur={() => onFieldBlur("communicationDate")}
              onDateChange={(value) =>
                onFieldValueChange("communicationDate", value)
              }
              error={!!communicationDateErrorText}
              required
              label={
                <Box gap="xs5">
                  {t("form.fields.communicationDate.label")}
                  <Typography
                    variant="body3"
                    variantColor={theme.colors.feedback.error[40]}
                  >
                    *
                  </Typography>
                </Box>
              }
              helperText={
                communicationDateErrorText ||
                t("form.fields.communicationDate.helperText")
              }
            />
          </Box>
        </StyledModalContent>
        <Modal.Footer>
          <Box
            alignItems="center"
            gap="xs2"
            justifyContent="space-between"
            $width={"100%"}
          >
            <div>
              <LinkButton variant="default" onClick={onClose}>
                {t("footer.buttons.cancel")}
              </LinkButton>
            </div>
            <Button
              variant="primary"
              loading={isLoading}
              disabled={isApproveButtonDisabled}
              onClick={() => onSubmit()}
            >
              {t("footer.buttons.approve")}
            </Button>
          </Box>
        </Modal.Footer>
      </>
    </Modal.Root>
  )
}

const localStyles = {
  helperText: {
    fontSize: "12px",
    lineHeight: "unset",
  },
}
