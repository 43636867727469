import {
  Button,
  Icons,
  Table,
  tableControllers,
} from "@flash-tecnologia/hros-web-ui-v2"
import React from "react"
import { useTranslation } from "react-i18next"
import { columns } from "./columns"
import { trpc } from "@api/client"
import { useDelayFn } from "@hooks"
import { RemoveArrayFromType, RouterOutput } from "@customTypes"
import dispatchToast from "@utils/dispatchToast"
import { RecognitionStatus } from "server/src/types"
import { generatePath, useNavigate } from "react-router-dom"
import { RecognitionDetails } from "@templates"
import { Action } from "@flash-tecnologia/hros-web-ui-v2/dist/components/Table/shared/table.types"
import i18n from "@i18n"
import { internalRoutes } from "src/routes/internalRoutes"

export enum TableRecognitionAvailableStatus {
  All = "all",
  NotStarted = "not-started",
  InProgress = "in-progress",
  Approved = "approved",
  Rejected = "rejected",
  // Canceled = "canceled", // next version only
}

type RecognitionTableProps = {
  filterStatus: TableRecognitionAvailableStatus
}

type PaginationState = {
  pageSize: number
  pageNumber: number
}

export type RecognitionItem = RemoveArrayFromType<
  RouterOutput["recognition"]["getAllRecognitions"]["data"]
>

type Details = {
  isOpen: boolean
  recognitionId: string
}

enum AvailableActions {
  details = "details",
  edit = "edit",
  startProcess = "startProcess",
  restartProcess = "restartProcess",
}

const paginationOptions = [
  {
    label: "10 itens",
    value: 10,
  },
  {
    label: "25 itens",
    value: 25,
  },
]

const RecognitionEmpty = () => {
  const navigate = useNavigate()
  const [t] = useTranslation("translations", {
    keyPrefix: "components.recognitionTable",
  })

  return (
    <Button
      variant="primary"
      size="large"
      onClick={() => navigate(internalRoutes.create.path)}
    >
      {t("addNewEmptyButtonLabel")}
      <Icons name="IconUserPlus" fill="transparent" />
    </Button>
  )
}

export const RecognitionTable = ({ filterStatus }: RecognitionTableProps) => {
  const [details, setDetails] = React.useState<Details | undefined>()
  const [searchInput, setSearchInput] = React.useState<string>("")
  const [pagination, setPagination] = React.useState<PaginationState>({
    pageNumber: 1,
    pageSize: 10,
  })

  const delayFn = useDelayFn(1000)
  const navigate = useNavigate()
  const utils = trpc.useUtils()
  const [t] = useTranslation("translations", {
    keyPrefix: "components.recognitionTable",
  })

  const { data: getAllRecogintionsResult, isFetching: isLoading } =
    trpc.recognition.getAllRecognitions.useQuery(
      {
        page: pagination.pageNumber,
        limit: pagination.pageSize,
        query: searchInput,
        status:
          filterStatus !== TableRecognitionAvailableStatus.All
            ? (filterStatus.toString() as RecognitionStatus)
            : undefined,
      },
      {
        retry: 0,
        refetchOnWindowFocus: false,
        onError: (err) => {
          dispatchToast({
            content:
              err.data?.userFriendlyError?.message ??
              i18n.t("error.internalServerError"),
            type: "error",
          })
        },
      },
    )

  const { mutateAsync: initiate } =
    trpc.recognition.initiateRecognition.useMutation({
      onError: (err) => {
        dispatchToast({
          content:
            err.data?.userFriendlyError?.message ??
            i18n.t("error.internalServerError"),
          type: "error",
        })
      },
    })

  const handleSearch = (input: string) => {
    delayFn(() => setSearchInput(input))
  }

  const actions: Record<
    AvailableActions,
    Action<RecognitionItem>
  > = React.useMemo(
    () => ({
      details: {
        label: t("actions.details"),
        icon: "IconFileDescription",
        key: "open",
        onClick: (data) => {
          setDetails({ isOpen: true, recognitionId: data.id })
        },
      },
      edit: {
        label: t("actions.edit"),
        icon: "IconPencil",
        key: "open",
        onClick: (data) => {
          navigate(
            generatePath(internalRoutes.edit.path, {
              id: data.id,
            }),
          )
        },
      },
      restartProcess: {
        label: t("actions.restartProcess"),
        icon: "IconRotate2",
        key: "open",
        onClick: (data) => {
          console.log(data)
        },
      },
      startProcess: {
        label: t("actions.startProcess"),
        icon: "IconArrowRight",
        key: "open",
        onClick: async (data) => {
          await initiate({
            recognitionId: data.id,
          })
          dispatchToast({
            type: "success",
            content: t("actions.initiateSuccessMessage"),
          })
          utils.recognition.getAllRecognitions.invalidate()
        },
      },
    }),
    [],
  )

  const actionsByStatus: Record<RecognitionStatus, Action<RecognitionItem>[]> =
    React.useMemo(
      () => ({
        [RecognitionStatus.InProgress]: [actions.details],
        [RecognitionStatus.Approved]: [actions.details],
        [RecognitionStatus.Canceled]: [actions.details, actions.restartProcess],
        [RecognitionStatus.Rejected]: [actions.details],
        [RecognitionStatus.NotStarted]: [
          actions.details,
          actions.edit,
          actions.startProcess,
        ],
      }),
      [],
    )

  const table = tableControllers.useTableColumns<RecognitionItem>({
    options: {
      actions: (context) => actionsByStatus[context.row.original.status],
    },
    columns: columns,
    data: getAllRecogintionsResult?.data ?? [],
    pagination: pagination,
    onPaginationChange: setPagination,
  })

  return (
    <Table.Root style={{ width: "100%" }}>
      <Table.Search
        label={t("tableSearchLabel")}
        onChange={(e) => handleSearch(e.target.value)}
      />

      <Table.Grid.Root
        empty={{
          message: t("tableEmptyLabel"),
          children: <RecognitionEmpty />,
        }}
        loading={isLoading}
      >
        <Table.Grid.Header getHeaderGroups={table.getHeaderGroups} />
        {table?.rows?.map((row, index) => (
          <Table.Grid.Row key={`${index}${row.id}`} row={row} />
        ))}
      </Table.Grid.Root>

      <Table.Pagination
        count={getAllRecogintionsResult?.pagination?.totalItems ?? 0}
        onPaginationChange={({ pageSize, pageNumber }) =>
          setPagination({ ...pagination, pageSize, pageNumber })
        }
        pagination={pagination}
        pageSizeOptions={paginationOptions}
      />

      {details?.isOpen && (
        <RecognitionDetails
          recognitionId={details.recognitionId}
          open={details.isOpen}
          onClose={() => setDetails(undefined)}
        />
      )}
    </Table.Root>
  )
}
