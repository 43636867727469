import { trpc } from "@api/client"
import { RecognitionType } from "@customTypes"
import { dayjs, Dayjs } from "@flash-tecnologia/hros-web-ui-v2"
import i18n from "@i18n"
import dispatchToast from "@utils/dispatchToast"
import { useFormik } from "formik"
import { useTranslation } from "react-i18next"
import * as yup from "yup"

interface ApproveRecognitionFormFields {
  communicationDate?: Dayjs
  effectiveMonthDate?: number
  effectiveYearDate?: number
}

const validationSchema = yup.object({
  communicationDate: yup
    .mixed<Dayjs>()
    .required(
      i18n.t(
        "components.modals.approveRecognition.form.fields.communicationDate.validation.required",
      ),
    ),
  effectiveMonthDate: yup
    .number()
    .required(
      i18n.t(
        "components.modals.approveRecognition.form.fields.effectiveMonthDate.validation.required",
      ),
    ),
  effectiveYearDate: yup
    .number()
    .required(
      i18n.t(
        "components.modals.approveRecognition.form.fields.effectiveYearDate.validation.required",
      ),
    ),
})

const typeDescriptions: Record<RecognitionType, string> = {
  [RecognitionType.Merit]: i18n.t(
    "components.modals.approveRecognition.types.merit",
  ),
  [RecognitionType.Promotion]: i18n.t(
    "components.modals.approveRecognition.types.promotion",
  ),
  [RecognitionType.Transfer]: i18n.t(
    "components.modals.approveRecognition.types.transfer",
  ),
}

const monthOptions =
  (
    i18n.t(
      "components.modals.approveRecognition.form.fields.effectiveMonthDate.options",
      {
        returnObjects: true,
      },
    ) as []
  )?.map((month, index) => {
    return { label: month, value: index + 1 }
  }) ?? []

const yearOptions =
  new Array(5).fill(0).map((_, index) => {
    const year = dayjs().year() + index
    return { label: year, value: year }
  }) ?? []

export function useApproveRecognition(
  recognitionId: string,
  onApproveSuccess: () => void,
) {
  const [t] = useTranslation("translations", {
    keyPrefix: "components.modals.approveRecognition",
  })

  const queryClient = trpc.useUtils()

  const { mutateAsync: approveRecognition, isLoading } =
    trpc.recognition.approveRecognition.useMutation({
      onError: (err) => {
        dispatchToast({
          content:
            err.data?.userFriendlyError?.message ??
            t("messages.approveFailure"),
          type: "error",
        })
      },
      onSuccess: () => {
        queryClient.recognition.getAllRecognitions.invalidate()
        dispatchToast({
          content: t("messages.approveSuccess.content"),
          type: "success",
        })
        onApproveSuccess()
      },
    })

  const approveRecognitionFormik = useFormik<ApproveRecognitionFormFields>({
    initialValues: {},
    validationSchema,
    onSubmit: ({
      communicationDate,
      effectiveMonthDate,
      effectiveYearDate,
    }) => {
      if (!communicationDate || !effectiveMonthDate || !effectiveYearDate) {
        dispatchToast({
          content: t("messages.missingFields"),
          type: "error",
        })
        return
      }
      approveRecognition({
        recognitionId,
        communicationDate: communicationDate.toDate().toISOString(),
        effectiveDate: dayjs()
          .set("date", 1)
          .set("month", effectiveMonthDate - 1)
          .set("year", effectiveYearDate)
          .toDate()
          .toISOString(),
      })
    },
    validate: (values) => {
      let errors: any = {}
      if (values?.effectiveMonthDate && values?.effectiveYearDate) {
        const effectiveDate = dayjs()
          .set("date", 1)
          .set("month", values.effectiveMonthDate - 1)
          .set("year", values.effectiveYearDate)

        const isEffectiveDateInvalid = effectiveDate.isBefore(dayjs())

        if (isEffectiveDateInvalid) {
          errors["effectiveMonthDate"] = t(
            "form.fields.effectiveMonthDate.validation.invalid",
          )
        }
      }

      return errors
    },
    validateOnBlur: true,
  })

  const handleFieldBlur = (fieldName: keyof ApproveRecognitionFormFields) => {
    approveRecognitionFormik.setFieldTouched(fieldName)
  }

  const handleFieldValueChange = (
    fieldName: keyof ApproveRecognitionFormFields,
    value: any,
  ) => {
    approveRecognitionFormik.handleChange({
      target: { name: fieldName, value },
    })
  }

  const effectiveMonthDateErrorText =
    approveRecognitionFormik.touched.effectiveMonthDate &&
    approveRecognitionFormik.errors.effectiveMonthDate

  const effectiveYearDateErrorText =
    approveRecognitionFormik.touched.effectiveYearDate &&
    approveRecognitionFormik.errors.effectiveYearDate

  const communicationDateErrorText =
    approveRecognitionFormik.touched.communicationDate &&
    approveRecognitionFormik.errors.communicationDate

  const isApproveButtonDisabled =
    !approveRecognitionFormik.values.communicationDate ||
    !!approveRecognitionFormik.errors.communicationDate ||
    !approveRecognitionFormik.values.effectiveMonthDate ||
    !!approveRecognitionFormik.errors.effectiveMonthDate ||
    !approveRecognitionFormik.values.effectiveYearDate ||
    !!approveRecognitionFormik.errors.effectiveYearDate

  return {
    communicationDateErrorText,
    effectiveMonthDateErrorText,
    effectiveYearDateErrorText,
    isApproveButtonDisabled,
    onFieldBlur: handleFieldBlur,
    onFieldValueChange: handleFieldValueChange,
    onSubmit: approveRecognitionFormik.handleSubmit,
    isLoading,
    monthOptions,
    typeDescriptions,
    yearOptions,
  }
}
