export const externalRoutes = {
  employeesProfile: {
    path: "/employees/:employeeId/profile",
  },
  authenticationLogin: {
    path: "/authentication/login",
  },
  home: {
    path: "/home",
  },
  settings: {
    createNewRole: "/settings/roles/create",
    createNewDepartment: "/settings/departments/create",
    createNewGroup: "/settings/create-group",
  },
}
