import { QueryClient } from "@tanstack/react-query"
import { createTRPCReact, httpBatchLink } from "@trpc/react-query"
import { getAccessToken } from "@flash-tecnologia/hros-web-utility"
import type { AppRouter } from "server"

export const trpc = createTRPCReact<AppRouter>()
export const queryClient = new QueryClient()

export const createTrpcClient = (selectedCompany: string) =>
  trpc.createClient({
    links: [
      httpBatchLink({
        headers: async () => {
          const token = await getAccessToken()
          return {
            Authorization: token,
            "X-Selected-Company": selectedCompany,
          }
        },
        url: `${process.env.TRPC_BFF_URL}/trpc`,
      }),
    ],
  })
