import { Box } from "@atoms"
import { Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { RecognitionFromStyled, RecognitionToStyled } from "./styles"
import { useTheme } from "styled-components"

export interface RecognitionUpdateProps {
  label?: string
  from?: string
  to: string
}

export const RecognitionUpdate = ({
  from,
  to,
  label,
}: RecognitionUpdateProps) => {
  const theme = useTheme()
  return (
    <Box flexDirection="column">
      <Typography
        variant="body4"
        variantColor={theme.colors.neutral[50]}
        weight={400}
      >
        {label}
      </Typography>
      <Box gap="xs2">
        {!!from && (
          <>
            <RecognitionFromStyled variant="body3">
              {from}
            </RecognitionFromStyled>
            <Icons
              name="IconArrowRight"
              fill="transparent"
              color={theme.colors.neutral[50]}
            />
          </>
        )}
        <RecognitionToStyled variant="body3">{to}</RecognitionToStyled>
      </Box>
    </Box>
  )
}
