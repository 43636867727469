import { Box } from "@atoms"
import { FocusedFlow } from "@organisms"
import {
  CustomItems,
  EmployeeVerticalInformation,
  RecognitionForm,
  RecognitionFormType,
  RecognitionFormRef,
} from "@templates"
import { Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { useTranslation } from "react-i18next"
import { useTheme } from "styled-components"
import * as yup from "yup"
import i18n from "@i18n"
import { trpc } from "@api/client"
import { currencyToNumberParser } from "@utils"
import dispatchToast from "@utils/dispatchToast"
import { useNavigate, useSearchParams } from "react-router-dom"
import { RecognitionType } from "@customTypes"
import React from "react"
import { internalRoutes } from "src/routes/internalRoutes"

type Breadcrumb = Parameters<typeof FocusedFlow.Header>[0]["breadcrumbs"]

const breadcrumbs: Breadcrumb = [
  {
    label: i18n.t("page.create.breadcrumbs.one"),
    route: "/recognition",
  },
  {
    label: i18n.t("page.create.breadcrumbs.two"),
  },
]

const fieldRequired = i18n.t("page.create.fieldRequired")

const validationSchema = yup.object({
  employee: yup.object().required(fieldRequired),
  type: yup
    .mixed<RecognitionType>()
    .oneOf(Object.values(RecognitionType), fieldRequired)
    .required(fieldRequired),
  role: yup.object(),
  department: yup.object(),
  groups: yup.array(),
  manager: yup.object(),
  salary: yup.string(),
  justification: yup.string().required(fieldRequired),
  newRole: yup.object(),
  newDepartment: yup.object(),
  newManager: yup.object(),
  newGroups: yup.array(),
  newSalary: yup
    .string()
    .typeError(fieldRequired)
    .min(1, fieldRequired)
    .required(fieldRequired),
})

function getCustomItems(values: RecognitionFormType) {
  const items: CustomItems = []
  if (values.newRole) {
    items.push({
      label: i18n.t("page.create.employeeCustomItems.newRole"),
      icon: "IconBriefcase",
      value:
        values.newRole?.label ??
        i18n.t("page.create.employeeCustomItems.notInformed"),
      order: 0,
    })
  }
  if (values.newDepartments?.length) {
    items.push({
      label: i18n.t("page.create.employeeCustomItems.newDepartment"),
      icon: "IconLayoutGrid",
      value:
        values.newDepartments.map(({ label }) => label).join(", ") ??
        i18n.t("page.create.employeeCustomItems.notInformed"),
      order: 1,
    })
  }
  if (values.newGroups?.length) {
    items.push({
      label: i18n.t("page.create.employeeCustomItems.newGroups"),
      icon: "IconUsers",
      value: values.newGroups
        ? values.newGroups.map(({ label }) => label).join(", ")
        : i18n.t("page.create.employeeCustomItems.notInformed"),
      order: 2,
    })
  }
  if (values.newSalary) {
    items.push({
      label: i18n.t("page.create.employeeCustomItems.newSalary"),
      icon: "IconCoin",
      value:
        values.newSalary ??
        i18n.t("page.create.employeeCustomItems.notInformed"),
      order: 3,
    })
  }
  if (values.newManager) {
    items.push({
      label: i18n.t("page.create.employeeCustomItems.newManager"),
      icon: "IconUserPlus",
      value:
        values.newManager.label ??
        i18n.t("page.create.employeeCustomItems.notInformed"),
      order: 4,
    })
  }
  return items
}

export const CreateRecognition = () => {
  const [shouldValidateOnChange, setShouldValidateOnChange] =
    React.useState(false)
  const [form, setForm] = React.useState<RecognitionFormRef>()

  const theme = useTheme()
  const navigate = useNavigate()
  const [t] = useTranslation("translations", {
    keyPrefix: "page.create",
  })

  const [searchParams] = useSearchParams()

  const employeeId = searchParams.get("employeeId")
  const type = searchParams.get("type")

  const {
    mutateAsync: createRecognition,
    isLoading: isCreateRecognitionLoading,
  } = trpc.recognition.createRecognition.useMutation({
    onError: (err) => {
      dispatchToast({
        content:
          err.data?.userFriendlyError?.message ??
          i18n.t("error.internalServerError"),
        type: "error",
      })
    },
  })

  const { data: employeeSelected } = trpc.employee.getCompanyEmployee.useQuery(
    { employeeId: form?.values.employee?.value ?? "" },
    {
      enabled: !!form?.values.employee?.value,
      cacheTime: 0,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (!form) return
        form.setValues((prev) => {
          const departments = data.departments?.map(({ name, id }) => ({
            label: name,
            value: id,
          }))
          const role = data.roles?.[0] && {
            label: data.roles[0].name,
            value: data.roles[0].id,
          }
          const groups = data.groups?.map(({ name, id }) => ({
            label: name,
            value: id,
          }))
          const manager = data.manager && {
            label: data.manager.name,
            value: data.manager.id,
          }
          const salary = data.salary
            ? Intl.NumberFormat("pt-BR").format(data.salary)
            : undefined
          return {
            ...prev,
            employee: { label: data.name, value: data.id },
            departments: departments,
            role: role,
            groups: groups,
            newDepartments: departments,
            newRole: role,
            newGroups: groups,
            manager: manager,
            salary: salary,
            newSalary: salary,
          }
        })
      },
      onError: (err) => {
        dispatchToast({
          content:
            err.data?.userFriendlyError?.message ??
            i18n.t("error.internalServerError"),
          type: "error",
        })
      },
    },
  )

  React.useEffect(() => {
    if (!form) return
    const { employee: formEmployee, type: formType } = form.values
    const fieldsToUpdate: Partial<RecognitionFormType> = {}

    if (!formEmployee?.value && employeeId)
      fieldsToUpdate.employee = { label: "", value: employeeId }
    if (!formType && type) fieldsToUpdate.type = type as RecognitionType

    if (Object.keys(fieldsToUpdate).length > 0) {
      form.setValues({
        ...form.values,
        ...fieldsToUpdate,
      })
    }
  }, [employeeId, type, form])

  const handleSubmit = async () => {
    if (!form) return
    setShouldValidateOnChange(true)
    const errors = await form.validateForm()
    if (Object.keys(errors)?.length > 0) {
      dispatchToast({
        content: t("fieldRequiredNotFilledError"),
        type: "error",
      })
      return
    }

    const recognitionForm: RecognitionFormType = await form.submitForm()
    if (
      !recognitionForm.employee ||
      !recognitionForm.type ||
      !recognitionForm.justification ||
      !recognitionForm.newSalary
    ) {
      dispatchToast({
        content: t("fieldRequiredNotFilledError"),
        type: "error",
      })
      return
    }

    await createRecognition({
      employeeId: recognitionForm.employee.value,
      justification: recognitionForm.justification,
      type: recognitionForm.type,
      salary: currencyToNumberParser(recognitionForm.salary ?? ""),
      newSalary: currencyToNumberParser(recognitionForm.newSalary ?? ""),
      role: recognitionForm.role,
      newRole: recognitionForm.newRole,
      departments: recognitionForm.departments,
      newDepartments: recognitionForm.newDepartments,
      groups: recognitionForm.groups,
      newGroups: recognitionForm.newGroups,
      manager: recognitionForm.manager,
      newManager: recognitionForm.newManager,
    })

    dispatchToast({
      content: t("recognitionCreatedMessage"),
      type: "success",
    })
    navigate(internalRoutes.home.path, {
      replace: true,
    })
  }

  return (
    <FocusedFlow.Root>
      <FocusedFlow.Header breadcrumbs={breadcrumbs} />
      <FocusedFlow.Content>
        <Box flexDirection="column" $px="s" $py="m" gap="xs">
          <Typography
            variant="headline6"
            variantColor={theme.colors.neutral[20]}
          >
            {t("title")}
          </Typography>

          <Box gap="xs">
            <Box flexDirection="column" $width={"25%"} gap="xs3">
              <Typography
                variant="headline7"
                variantColor={theme.colors.secondary[50]}
              >
                {t("subtitle")}
              </Typography>
              <Typography
                variant="body3"
                variantColor={theme.colors.neutral[50]}
              >
                {t("description")}
              </Typography>
            </Box>

            <Box flexDirection="column" flex={1} gap="s">
              <RecognitionForm
                ref={(node: RecognitionFormRef) => setForm(node)}
                validateOnChange={shouldValidateOnChange}
                validationSchema={validationSchema}
              />
            </Box>

            <Box flexDirection="column" $width={"25%"}>
              {!!employeeSelected && (
                <EmployeeVerticalInformation
                  employee={{
                    ...employeeSelected,
                    roles: form?.values.role
                      ? [
                          {
                            id: form.values.role.value,
                            name: form.values.role.label,
                            description: "",
                          },
                        ]
                      : employeeSelected.roles,
                    departments: form?.values.departments
                      ? form.values.departments.map(({ label, value }) => ({
                          id: value,
                          name: label,
                          description: "",
                        }))
                      : employeeSelected.departments,
                    groups: form?.values.groups
                      ? form.values.groups.map(({ label, value }) => ({
                          id: value,
                          name: label,
                        }))
                      : employeeSelected.groups,
                    salary: currencyToNumberParser(form?.values.salary ?? ""),
                    manager: employeeSelected.manager,
                  }}
                  customItems={form?.values ? getCustomItems(form.values) : []}
                />
              )}
            </Box>
          </Box>
        </Box>
      </FocusedFlow.Content>
      <FocusedFlow.Footer
        primaryButton={{
          text: t("footer.submitButtonLabel"),
          icon: "IconCheck",
          disabled: !form?.values.type || !form?.values.employee,
          onClick: handleSubmit,
          loading: isCreateRecognitionLoading,
        }}
        cancelButton={{
          text: t("footer.cancelButtonLabel"),
          onClick: () =>
            navigate(internalRoutes.home.path, {
              replace: true,
            }),
        }}
      ></FocusedFlow.Footer>
    </FocusedFlow.Root>
  )
}
