import { trpc } from "@api/client"
import { Box } from "@atoms"
import { RecognitionStatus, RouterOutput } from "@customTypes"
import { Divider, Tag, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import i18n from "@i18n"
import { HorizontalBigNumber } from "@molecules"
import { Drawer } from "@organisms"
import { getPercentageComparison, stringToCurrencyParser } from "@utils"
import dispatchToast from "@utils/dispatchToast"
import { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import { useTheme } from "styled-components"
import { Footer } from "./Footer"
import { Header } from "./Header"
import { ContentSkeleton } from "./LoadingSkeleton"
import { ModalApprove } from "./ModalApprove"
import { RecognitionUpdateProps } from "./ModalApprove/RecognitionUpdate"
import { ModalReject } from "./ModalReject"
import { RecognitionApprovalSection } from "./RecognitionApprovalSection"
import { RecognitionRejectionSection } from "./RecognitionRejectionSection"

type RecognitionDetailsProps = {
  recognitionId: string
  open: boolean
  onClose: () => void
}

export type GetRecognitionType =
  RouterOutput["recognition"]["getRecognitionById"]

export const RecognitionDetails = ({
  recognitionId,
  open,
  onClose,
}: RecognitionDetailsProps) => {
  const theme = useTheme()
  const [t] = useTranslation("translations", {
    keyPrefix: "components.recognitionDetails",
  })

  const { data: recognition, isFetching } =
    trpc.recognition.getRecognitionById.useQuery(
      { recognitionId },
      {
        refetchOnWindowFocus: false,
        onError: (err) => {
          dispatchToast({
            content:
              err.data?.userFriendlyError?.message ??
              i18n.t("error.internalServerError"),
            type: "error",
          })
        },
      },
    )

  const [modalApproveVisible, setModalApproveVisible] = useState(false)
  const [modalRejectVisible, setModalRejectVisible] = useState(false)

  const handleApproveSuccess = () => {
    setModalApproveVisible(false)
    onClose()
  }

  const handleRejectSuccess = () => {
    setModalRejectVisible(false)
    onClose()
  }

  const getRecognitionUpdates = useCallback(() => {
    const recognitionUpdates: RecognitionUpdateProps[] = []
    if (recognition?.role) {
      recognitionUpdates.push({
        from: recognition.role.from.name,
        to: recognition.role.to.name,
      })
    }
    if (recognition?.department) {
      recognitionUpdates.push({
        from: recognition.department.from.name,
        to: recognition.department.to.name,
      })
    }
    if (recognition?.salary) {
      recognitionUpdates.push({
        from: stringToCurrencyParser(recognition.salary.from),
        to: stringToCurrencyParser(recognition.salary.to),
      })
    }

    return recognitionUpdates
  }, [recognition])

  const handleModalApproveOpen = () => {
    setModalApproveVisible(true)
  }
  const handleModalApproveClose = () => {
    setModalApproveVisible(false)
  }

  const handleModalRejectOpen = () => {
    setModalRejectVisible(true)
  }
  const handleModalRejectClose = () => {
    setModalRejectVisible(false)
  }

  return (
    <>
      {recognition && (
        <ModalApprove
          isOpen={modalApproveVisible}
          onClose={handleModalApproveClose}
          recognitionId={recognitionId}
          recognitionType={recognition.type}
          recognitionUpdates={getRecognitionUpdates()}
          onApproveSuccess={handleApproveSuccess}
        />
      )}
      {recognition && (
        <ModalReject
          isOpen={modalRejectVisible}
          onClose={handleModalRejectClose}
          recognitionId={recognitionId}
          onRejectSuccess={handleRejectSuccess}
        />
      )}
      <Drawer.Root open={open}>
        <Header
          recognition={recognition}
          isLoading={isFetching}
          onClose={onClose}
        />

        {isFetching ? (
          <ContentSkeleton />
        ) : (
          <Drawer.Content>
            <Box flexDirection="column" $py="xs" $px="m" gap="xs">
              <Typography
                variant="headline8"
                variantColor={theme.colors.neutral[30]}
              >
                {t("content.title")}
              </Typography>

              <Box flexDirection="row" gap="xs">
                <Box flexDirection="column">
                  <Typography
                    variant="caption"
                    variantColor={theme.colors.neutral[50]}
                  >
                    {t("content.requestedBy")}
                  </Typography>
                  <Tag variant="pink">{recognition?.requestedByName}</Tag>
                </Box>

                {recognition?.managerName && (
                  <Box flexDirection="column">
                    <Typography
                      variant="caption"
                      variantColor={theme.colors.neutral[50]}
                    >
                      {t("content.manager")}
                    </Typography>
                    <Tag variant="pink">{recognition?.managerName}</Tag>
                  </Box>
                )}
              </Box>

              {recognition?.role && (
                <Box flexDirection="column">
                  <Typography
                    variant="caption"
                    variantColor={theme.colors.neutral[50]}
                  >
                    {t("content.fromRole")}
                  </Typography>
                  <Typography
                    variant="body3"
                    variantColor={theme.colors.neutral[30]}
                  >
                    {recognition.role.from.name}
                  </Typography>
                </Box>
              )}

              {recognition?.department && (
                <Box flexDirection="column">
                  <Typography
                    variant="caption"
                    variantColor={theme.colors.neutral[50]}
                  >
                    {t("content.fromDepartment")}
                  </Typography>
                  <Typography
                    variant="body3"
                    variantColor={theme.colors.neutral[30]}
                  >
                    {recognition.department.from.name}
                  </Typography>
                </Box>
              )}

              {recognition?.salary && (
                <Box flexDirection="column">
                  <Typography
                    variant="caption"
                    variantColor={theme.colors.neutral[50]}
                  >
                    {t("content.fromSalary")}
                  </Typography>
                  <Typography
                    variant="body3"
                    variantColor={theme.colors.neutral[30]}
                  >
                    {stringToCurrencyParser(recognition.salary?.from)}
                  </Typography>
                </Box>
              )}

              <Box flexDirection="column">
                <Typography
                  variant="caption"
                  variantColor={theme.colors.neutral[50]}
                >
                  {t("content.justification")}
                </Typography>
                <Typography
                  variant="body3"
                  variantColor={theme.colors.neutral[30]}
                >
                  {recognition?.justification}
                </Typography>
              </Box>

              <Divider orientation="horizontal" />

              <Typography
                variant="headline8"
                variantColor={theme.colors.neutral[30]}
              >
                {t("content.recognitionTitle")}
              </Typography>

              {recognition?.role && (
                <Box flexDirection="column">
                  <Typography
                    variant="caption"
                    variantColor={theme.colors.neutral[50]}
                  >
                    {t("content.toRole")}
                  </Typography>
                  <Typography
                    variant="body3"
                    variantColor={theme.colors.neutral[30]}
                  >
                    {recognition.role.to.name}
                  </Typography>
                </Box>
              )}

              {recognition?.department && (
                <Box flexDirection="column">
                  <Typography
                    variant="caption"
                    variantColor={theme.colors.neutral[50]}
                  >
                    {t("content.toDepartment")}
                  </Typography>
                  <Typography
                    variant="body3"
                    variantColor={theme.colors.neutral[30]}
                  >
                    {recognition.department.to.name}
                  </Typography>
                </Box>
              )}

              {recognition?.salary && (
                <Box flexDirection="column">
                  <Typography
                    variant="caption"
                    variantColor={theme.colors.neutral[50]}
                  >
                    {t("content.toSalary")}
                  </Typography>
                  <Typography
                    variant="body3"
                    variantColor={theme.colors.neutral[30]}
                  >
                    {stringToCurrencyParser(recognition.salary?.to)}
                  </Typography>
                </Box>
              )}

              {recognition?.salary && (
                <HorizontalBigNumber
                  icon="IconCoin"
                  iconColors={{
                    primary: theme.colors.feedback.success[40],
                    secondary: theme.colors.feedback.success[90],
                  }}
                  title={t("content.bigNumberLabel")}
                  textColor={theme.colors.feedback.success[40]}
                  value={getPercentageComparison(
                    recognition?.salary.from,
                    recognition?.salary.to,
                  )}
                />
              )}

              {recognition?.status === RecognitionStatus.Approved && (
                <RecognitionApprovalSection
                  communicationDate={recognition.communicationDate ?? ""}
                  effectiveDate={recognition.effectiveDate ?? ""}
                />
              )}

              {recognition?.status === RecognitionStatus.Rejected && (
                <RecognitionRejectionSection
                  rejectReason={recognition.rejectReason ?? ""}
                />
              )}
            </Box>
          </Drawer.Content>
        )}

        {recognition && (
          <Footer
            recognition={recognition}
            onClose={onClose}
            onApproveClick={handleModalApproveOpen}
            onRejectClick={handleModalRejectOpen}
          />
        )}
      </Drawer.Root>
    </>
  )
}
