import { Drawer } from "@organisms"
import { RecognitionStatus, RecognitionType } from "server/src/types"
import { GetRecognitionType } from "."
import { dayjs, Tag, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { useTheme } from "styled-components"
import { useTranslation } from "react-i18next"
import i18n from "@i18n"

const typeTranslations: Record<RecognitionType, string> = {
  [RecognitionType.Merit]: i18n.t("components.recognitionDetails.types.merit"),
  [RecognitionType.Promotion]: i18n.t(
    "components.recognitionDetails.types.promotion",
  ),
  [RecognitionType.Transfer]: i18n.t(
    "components.recognitionDetails.types.transfer",
  ),
}

const statusTag: Record<
  RecognitionStatus,
  {
    variant: React.ComponentProps<typeof Drawer.Header>["tags"][0]["variant"]
    text: string
  }
> = {
  [RecognitionStatus.Approved]: {
    text: i18n.t("components.recognitionDetails.status.approved"),
    variant: "green",
  },
  [RecognitionStatus.Canceled]: {
    text: i18n.t("components.recognitionDetails.status.canceled"),
    variant: "yellow",
  },
  [RecognitionStatus.Rejected]: {
    text: i18n.t("components.recognitionDetails.status.rejected"),
    variant: "yellow",
  },
  [RecognitionStatus.InProgress]: {
    text: i18n.t("components.recognitionDetails.status.inProgress"),
    variant: "blue",
  },
  [RecognitionStatus.NotStarted]: {
    text: i18n.t("components.recognitionDetails.status.notStarted"),
    variant: "gray",
  },
}

type DrawerHeaderProps = React.ComponentProps<typeof Drawer.Header>

type HeaderProps = {
  recognition?: GetRecognitionType
  isLoading: boolean
  onClose: DrawerHeaderProps["onClose"]
}

export const Header = ({ recognition, isLoading, onClose }: HeaderProps) => {
  const theme = useTheme()
  const [t] = useTranslation("translations", {
    keyPrefix: "components.recognitionDetails",
  })

  return (
    <Drawer.Header
      title={t("title")}
      onClose={onClose}
      tags={recognition ? [statusTag[recognition.status]] : []}
      loading={isLoading}
      contents={[
        {
          title: t("createdAtLabel"),
          value: (
            <Typography
              variant="headline8"
              variantColor={theme.colors.neutral[30]}
            >
              {recognition?.createdAt
                ? dayjs(recognition?.createdAt).format("DD/MM/YYYY")
                : ""}
            </Typography>
          ),
        },
        {
          title: t("typeLabel"),
          value: (
            <Typography
              variant="headline8"
              variantColor={theme.colors.neutral[30]}
            >
              {recognition?.type ? typeTranslations[recognition?.type] : ""}
            </Typography>
          ),
        },
        {
          title: t("personLabel"),
          value: <Tag variant="pink">{recognition?.employeeName}</Tag>,
        },
      ]}
    />
  )
}
