import { Box, RoundedButton } from "@atoms"
import {
  GenericProfilePicture,
  IconTypes,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"
import { ItemMenu } from "@molecules"
import { useTheme } from "styled-components"

type Button = {
  icon: IconTypes
  text: string
  callback: () => void
}

type Item = {
  icon: IconTypes
  label: string
  value: string
  loading?: boolean
  order?: number
}

type VerticalInformationProps = {
  name: string
  profilePicture?: string
  profileButton?: Button
  items: Item[]
  subItems?: Item[]
}

export const VerticalInformation = ({
  name,
  profilePicture,
  profileButton,
  items,
  subItems = [],
}: VerticalInformationProps) => {
  const theme = useTheme()

  const sortedItems = items.sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
  const sortedSubItems = subItems.sort(
    (a, b) => (a.order ?? 0) - (b.order ?? 0),
  )

  return (
    <Box flexDirection="column" gap="s">
      <Box
        flexDirection="column"
        alignItems="center"
        gap="xs2"
        $pb="s"
        $borderBottom={`1px solid ${theme.colors.neutral[90]}`}
        $textAlign="center"
      >
        <GenericProfilePicture name={name} source={profilePicture} size={124} />
        <Typography
          variant="headline6"
          variantColor={theme.colors.secondary[50]}
        >
          {name}
        </Typography>
        {profileButton && (
          <RoundedButton
            icon={profileButton.icon}
            onClick={profileButton.callback}
          >
            {profileButton.text}
          </RoundedButton>
        )}
      </Box>

      <Box flexDirection="column" gap="xs2">
        {sortedItems.map((item, idx) => (
          <ItemMenu
            key={`${item.label}-${idx}`}
            label={item.label}
            value={item.value}
            loading={item.loading}
            icon={item.icon}
          />
        ))}
      </Box>

      {sortedSubItems?.length > 0 && (
        <Box
          flexDirection="column"
          gap="xs2"
          $pt="s"
          $borderTop={`1px solid ${theme.colors.neutral[90]}`}
        >
          {sortedSubItems.map((item, idx) => (
            <ItemMenu
              key={`sub-${item.label}-${idx}`}
              label={item.label}
              value={item.value}
              loading={item.loading}
              icon={item.icon}
            />
          ))}
        </Box>
      )}
    </Box>
  )
}
