import { Box } from "@atoms"
import { Drawer } from "@mui/material"

export type RootProps = {
  open: boolean
  children?: React.ReactNode
}

export const Root = ({ open, children }: RootProps) => {
  return (
    <Drawer anchor="right" open={open} style={{ zIndex: 1000 }}>
      <Box flexDirection="column" $height={"100vh"}>
        {children}
      </Box>
    </Drawer>
  )
}
