export enum RecognitionStatus {
  NotStarted = "not-started",
  InProgress = "in-progress",
  Approved = "approved",
  Rejected = "rejected",
  Canceled = "canceled",
}

export enum RecognitionType {
  Promotion = "promotion",
  Merit = "merit",
  Transfer = "transfer",
}

export interface Role {
  from: { id: string; name: string }
  to: { id: string; name: string }
}

export interface Department {
  from: { id: string; name: string }
  to: { id: string; name: string }
}

export interface Salary {
  from: number
  to: number
}

export interface RecognitionEntity {
  id: string
  employeeId: string
  companyId: string
  status: RecognitionStatus
  type: RecognitionType
  justification?: string
  updatedBy: string
  rejectReason?: string
  effectiveDate?: Date
  communicationDate?: Date
  role?: Role
  department?: Department
  salary?: Salary
  createdAt: Date
  updatedAt: Date
  requestedBy: string
}

export interface FindRecognition extends RecognitionEntity {
  employeeName: string
  employeeProfilePicture?: string
  managerId?: string
  managerName?: string
  managerProfilePicture?: string
}

export interface FindRecognitionById extends RecognitionEntity {
  requestedByName: string
  requestedByProfilePicture?: string
  employeeName: string
  employeeProfilePicture?: string
  managerId?: string
  managerName?: string
  managerProfilePicture?: string
}
