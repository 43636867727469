import {
  dayjs,
  GenericProfilePicture,
  Icons,
  tableControllers,
  Tag,
} from "@flash-tecnologia/hros-web-ui-v2"
import i18n from "@i18n"
import { ReactNode } from "react"
import { ItemBox, ThreeLineSpan, EllipsisSpan } from "./styles"
import { RecognitionItem } from "./Table"

type TableColumns = Parameters<
  typeof tableControllers.useTableColumns<RecognitionItem>
>[0]["columns"]

const statusTags: Record<RecognitionItem["status"], ReactNode> = {
  "in-progress": (
    <Tag variant="blue" as="span">
      <>
        {i18n.t("components.recognitionTable.columns.status.inProgressLabel")}
      </>
    </Tag>
  ),
  "not-started": (
    <Tag variant="gray" as="span">
      <>
        {i18n.t("components.recognitionTable.columns.status.notStartedLabel")}
      </>
    </Tag>
  ),
  approved: (
    <Tag variant="green" as="span">
      <>{i18n.t("components.recognitionTable.columns.status.approvedLabel")}</>
    </Tag>
  ),
  canceled: (
    <Tag variant="yellow" as="span">
      <>{i18n.t("components.recognitionTable.columns.status.canceledLabel")}</>
    </Tag>
  ),
  rejected: (
    <Tag variant="yellow" as="span">
      <>{i18n.t("components.recognitionTable.columns.status.rejectedLabel")}</>
    </Tag>
  ),
}

export const columns: TableColumns = [
  {
    header: i18n.t("components.recognitionTable.columns.createdAt.title"),
    accessorKey: "createdAt",
    cell: (context) => (
      <ItemBox gap="xs3">
        <Icons name="IconCalendarEvent" fill="transparent" />
        <EllipsisSpan>
          {dayjs(context.cell.row.original.createdAt).format("DD/MM/YYYY")}
        </EllipsisSpan>
      </ItemBox>
    ),
  },
  {
    header: i18n.t("components.recognitionTable.columns.name.title"),
    accessorKey: "employee",
    cell: (context) => (
      <ItemBox gap="xs4" alignItems="center">
        <GenericProfilePicture
          name={context.cell.row.original.employee?.name}
          source={context.cell.row.original.employee?.profilePicture}
          size={32}
        />
        <EllipsisSpan>{context.cell.row.original.employee?.name}</EllipsisSpan>
      </ItemBox>
    ),
  },
  {
    header: i18n.t("components.recognitionTable.columns.type.title"),
    accessorKey: "type",
    cell: (context) => (
      <ItemBox>
        <EllipsisSpan>
          <Tag variant="primary" as="span">
            <>{i18n.t(`recognitionTypes.${context.cell.row.original.type}`)}</>
          </Tag>
        </EllipsisSpan>
      </ItemBox>
    ),
  },
  {
    header: i18n.t("components.recognitionTable.columns.status.title"),
    accessorKey: "status",
    cell: (context) => (
      <ItemBox>{statusTags[context.cell.row.original.status]}</ItemBox>
    ),
  },
  {
    header: i18n.t("components.recognitionTable.columns.leader.title"),
    accessorKey: "manager",
    cell: (context) =>
      context.cell.row.original.manager?.name ? (
        <ItemBox gap="xs4" alignItems="center">
          <GenericProfilePicture
            name={context.cell.row.original.manager?.name}
            source={context.cell.row.original.manager?.profilePicture}
            size={32}
          />
          <EllipsisSpan>{context.cell.row.original.manager?.name}</EllipsisSpan>
        </ItemBox>
      ) : (
        <></>
      ),
  },
  {
    header: i18n.t("components.recognitionTable.columns.justification.title"),
    accessorKey: "justification",
    cell: (context) => (
      <ItemBox>
        <ThreeLineSpan>{context.cell.row.original.justification}</ThreeLineSpan>
      </ItemBox>
    ),
  },
]
