import { RecognitionType } from "@customTypes"

export default {
  translations: {
    page: {
      home: {
        title: "Reconhecimentos",
        addButtonLabel: "Solicitar reconhecimento",
        tabs: {
          all: "Todos",
          notStarted: "Não iniciado",
          inProgress: "Em andamento",
          approved: "Aprovado",
          rejected: "Reprovado",
          canceled: "Cancelado",
        },
      },
      create: {
        title: "Solicitar reconhecimento",
        subtitle: "Dados do reconhecimento",
        description:
          "Insira pessoas no processo de reconhecimento e deixe seus talentos voarem na velocidade da luz ⚡",
        employeeCustomItems: {
          newSalary: "Novo salário",
          newRole: "Novo cargo",
          newDepartment: "Novos departamentos",
          newGroups: "Novos grupos",
          newManager: "Novo líder",
          notInformed: "Não cadastrado",
        },
        footer: {
          submitButtonLabel: "Solicitar reconhecimento",
          cancelButtonLabel: "Cancelar",
        },
        breadcrumbs: {
          one: "Reconhecimentos",
          two: "Solicitar reconhecimento",
        },
        fieldRequiredNotFilledError:
          "Existem campos obrigatórios não preenchidos, por favor verifique o formulário",
        recognitionCreatedMessage: "Reconhecimento criado com sucesso!",
        fieldRequired: "Campo obrigatório",
      },
      edit: {
        title: "Editar reconhecimento",
        subtitle: "Dados do reconhecimento",
        description:
          "Insira pessoas no processo de reconhecimento e deixe seus talentos voarem na velocidade da luz ⚡",
        employeeCustomItems: {
          newSalary: "Novo salário",
          newRole: "Novo cargo",
          newDepartment: "Novos departamentos",
          newGroups: "Novos grupos",
          newManager: "Novo líder",
          notInformed: "Não cadastrado",
        },
        footer: {
          submitButtonLabel: "Editar reconhecimento",
          cancelButtonLabel: "Cancelar",
        },
        breadcrumbs: {
          one: "Reconhecimentos",
          two: "Editar reconhecimento",
        },
        fieldRequiredNotFilledError:
          "Existem campos obrigatórios não preenchidos, por favor verifique o formulário",
        recognitionEditedMessage: "Reconhecimento editado com sucesso!",
        fieldRequired: "Campo obrigatório",
      },
    },
    components: {
      commentary: {
        title: "Comentários",
        textareaPlaceholder: "Use @ para mencionar alguém...",
        showAllLabel: "Ver mais",
        showLessLabel: "Ver menos",
        initialTimeSince: "há poucos segundos",
      },
      featureNotification: {
        features: {
          recognition: {
            title: "Simplifique a gestão de promoções da sua empresa.",
            description:
              "Com a nova solução RHs podem criar e enviar para aprovação da gerência pedidos de promoção, mérito e movimentações para colaboradores e acompanhar em tempo real o status de cada pedido, tudo em um só lugar.",
          },
        },
        newLabel: "Novidade!",
        backButtonLabel: "Voltar",
        laterButtonLabel: "Ver mais tarde",
        startUseButtonLabel: "Começar a usar",
        nextButtonLabel: "Próximo",
      },
      recognitionTable: {
        tableSearchLabel: "Buscar por pessoa",
        tableEmptyLabel:
          "Você ainda não solicitou nenhum reconhecimento das pessoas na sua empresa.",
        addNewEmptyButtonLabel: "Solicitar reconhecimento",
        columns: {
          createdAt: { title: "Data da solicitação" },
          name: { title: "Pessoa" },
          type: {
            title: "Tipo de reconhecimento",
            meritLabel: "Mérito",
            promotionLabel: "Promoção",
            transferLabel: "Transferência",
          },
          status: {
            title: "Status",
            inProgressLabel: "Em andamento",
            notStartedLabel: "Não iniciado",
            approvedLabel: "Aprovado",
            rejectedLabel: "Reprovado",
            canceledLabel: "Cancelado",
          },
          leader: { title: "Líder" },
          justification: { title: "Justificativa do reconhecimento" },
        },
        actions: {
          startProcess: "Iniciar processo",
          restartProcess: "Reiniciar processo",
          cancelProcess: "Cancelar reconhecimento",
          details: "Ver detalhes do reconhecimento",
          edit: "Editar reconhecimento",
          initiateSuccessMessage: "Processo iniciado com sucesso!",
        },
        filters: {
          title: "Filtrar por:",
          solicitationDate: { label: "Data da solicitação" },
          managers: { label: "Líder" },
          status: {
            label: "Status",
            notStarted: "Não iniciado",
            inProgress: "Em andamento",
            approved: "Aprovado",
            rejected: "Reprovado",
            canceled: "Cancelado",
          },
          types: {
            label: "Tipo de reconhecimento",
            merit: "Mérito",
            transfer: "Transferência",
            promotion: "Promoção",
          },
        },
      },
      recognitionDetails: {
        title: "Detalhes do reconhecimento",
        tabs: {
          overview: "Visão geral",
          processHistory: "Histórico do processo",
        },
        content: {
          title: "Informações básicas",
          requestedBy: "Solicitado por",
          manager: "Líder",
          fromRole: "Cargo vigente",
          fromDepartment: "Departamentos vigentes",
          fromGroups: "Grupos vigentes",
          fromSalary: "Salário vigente",
          justification: "Justificativa do reconhecimento",
          recognitionTitle: "Informações do reconhecimento",
          toRole: "Novo cargo",
          toDepartment: "Novos departamentos",
          toGroups: "Novos grupos",
          toSalary: "Novo salário",
          bigNumberLabel: "Em comparação com o salário vigente",
          approval: {
            title: "Aprovação",
            effectiveDate: "Mês de vigência do reconhecimento",
            communicationDate: "Data indicada para comunicação",
          },
          rejection: {
            title: "Reprovação",
            rejectReason: "Justificativa da reprovação",
          },
        },
        footer: {
          restartLabel: "Reiniciar processo",
          startProcessLabel: "Iniciar processo",
          approveLabel: "Aprovar",
          rejectLabel: "Reprovar",
          initiateSuccessMessage: "Processo iniciado com sucesso!",
          updates: {
            role: "Cargo",
            department: "Departamento",
            group: "Grupo",
            manager: "Líder",
            salary: "Salário",
          },
        },
        createdAtLabel: "Data da solicitação",
        typeLabel: "Tipo de reconhecimento",
        personLabel: "Pessoa",
      },
      recognitionForm: {
        content: {
          basicInformationTitle: "Informações básicas",
          basicInformationDescription:
            "Preencha as informações básicas do reconhecimento para continuar. Caso não encontre a pessoa, confirme com o seu RH se você está vinculado como líder.",
          selectPersonLabel: "Selecione a pessoa",
          selectPersonNoSearchLabel: "Digite ao menos 3 letras para buscar",
          typeRecognitionLabel: "Tipo de reconhecimento",
          roleSelectLabel: "Cargo vigente",
          departmentSelectLabel: "Departamentos vigentes",
          groupsSelectLabel: "Grupos vigentes",
          salaryLabel: "Salário vigente",
          justifyRecognitionLabel: "Justificativa do reconhecimento",
          cycleTitle: "Ciclo de avaliações",
          cycleDescription:
            "Selecione o ciclo de avaliações vinculado ao reconhecimento.",
          selectCycle: "Selecione o ciclo",
        },
        recognitionContent: {
          title: "Informações do reconhecimento",
          description:
            "Preencha as novas informações da pessoa após o reconhecimento.",
          newRoleLabel: "Novo cargo",
          createNewRole: "Criar novo cargo",
          newDepartmentLabel: "Novos departamentos",
          createNewDepartment: "Criar novo departamento",
          newGroupsLabel: "Novos grupos",
          createNewGroup: "Criar novo grupo",
          newSalaryLabel: "Novo salário",
          comparationLabel: "Em comparação com o salário vigente",
          bigNumberLabel: "Em comparação com o salário vigente",
          newManagerLabel: "Novo líder",
          newManagerNoSearchLabel: "Digite ao menos 3 letras para buscar",
        },
        selectNoOptionsLabel: "Nenhum resultado encontrado",
        selectLoadingLabel: "Buscando...",
        requiredField: "Campo obrigatório",
      },
      employeeVerticalInformation: {
        buttonRedirectLabel: "Ver perfil da pessoa",
        items: {
          fullName: "Nome completo",
          documentNumber: "CPF",
          email: "E-mail",
          manager: "Líder",
          salary: "Salário vigente",
          role: "Cargo vigente",
          department: "Departamentos vigentes",
          groups: "Grupos vigentes",
        },
      },
      clipboard: {
        textCopied: "Texto copiado!",
      },
      modals: {
        confirmationModal: {
          iconLabel: "Atenção!",
          footer: {
            buttons: {
              cancel: "Cancelar",
              submit: "Confirmar",
            },
          },
        },
        approveRecognition: {
          iconLabel: "Atenção!",
          title: "Tem certeza que deseja aprovar o reconhecimento?",
          description:
            "Essa ação irá alterar as informações básicas do colaborador e afetar outros módulos como: <strong>Controle de Jornada, Performance e Organograma.</strong>",
          changeDetailsTitle: "Transferência",
          form: {
            fields: {
              communicationDate: {
                label: "Data indicada para comunicação",
                helperText:
                  "Data indicada para os gestores ou administradores enviarem a comunicação do reconhecimento para o colaborador.",
                validation: {
                  required: "Campo obrigatório",
                },
              },
              effectiveMonthDate: {
                label: "Mês de vigência",
                helperText:
                  "Mês e ano em que o novo reconhecimento passa a valer na folha de pagamento.",
                options: [
                  "Janeiro",
                  "Fevereiro",
                  "Março",
                  "Abril",
                  "Maio",
                  "Junho",
                  "Julho",
                  "Agosto",
                  "Setembro",
                  "Outubro",
                  "Novembro",
                  "Dezembro",
                ],
                validation: {
                  required: "Campo obrigatório",
                  invalid: "Mês de vigência inválido",
                },
              },
              effectiveYearDate: {
                label: "Ano de vigência",
                validation: {
                  required: "Campo obrigatório",
                },
              },
            },
          },
          footer: {
            buttons: {
              cancel: "Cancelar",
              approve: "Aprovar reconhecimento",
            },
          },
          messages: {
            missingFields:
              "Verifique os campos do formulário antes de aprovar o reconhecimento.",
            approveFailure: "Falha ao aprovar o reconhecimento.",
            approveSuccess: {
              content: "Reconhecimento aprovado com sucesso!",
            },
          },
        },
        rejectRecognition: {
          iconLabel: "Atenção!",
          title: "Tem certeza que deseja reprovar o reconhecimento?",
          description:
            "Você pode reiniciar o processo deste reconhecimento após fazer a reprovação.",
          form: {
            fields: {
              rejectReason: {
                label: "Justificativa da reprovação",
                validation: {
                  required: "Campo obrigatório",
                },
              },
            },
          },
          footer: {
            buttons: {
              cancel: "Cancelar",
              reject: "Reprovar reconhecimento",
            },
          },
          messages: {
            missingFields:
              "Adicione uma justificativa antes de reprovar o reconhecimento.",
            rejectFailure:
              "Ocorreu um erro ao reprovar o reconhecimento. Tente novamente mais tarde.",
            rejectSuccess: {
              content: "Reconhecimento reprovado com sucesso!",
            },
          },
        },
        restartRecognition: {
          title: "Tem certeza que deseja reiniciar o processo?",
          description:
            "Essa ação irá retornar o processo deste reconhecimento para <strong>Não iniciado.</strong>",
          confirmButtonLabel: "Reiniciar processo",
          messages: {
            restartSuccess: "Processo reiniciado com sucesso!",
          },
        },
        cancelRecognition: {
          title: "Tem certeza que deseja cancelar o reconhecimento?",
          description:
            "O reconhecimento será cancelado e arquivado na aba de <strong>Cancelado</strong> apenas para consulta.",
          confirmButtonLabel: "Cancelar reconhecimento",
          messages: {
            cancelSuccess: "Reconhecimento cancelado com sucesso!",
          },
        },
      },
      historyItem: {
        by: "Por",
      },
    },
    recognitionStatus: {
      approved: "Aprovado",
      canceled: "Cancelado",
      rejected: "Reprovado",
      inProgress: "Em andamento",
      notStarted: "Não iniciado",
    },
    recognitionTypes: {
      [RecognitionType.PositionChange]: "Alteração de cargo",
      [RecognitionType.RoleChange]: "Alteração de função",
      [RecognitionType.Effectivation]: "Efetivação",
      [RecognitionType.FrameworkAdjustment]: "Enquadramento",
      [RecognitionType.SalaryEqualization]: "Equiparação salarial",
      [RecognitionType.Promotion]: "Promoção",
      [RecognitionType.Merit]: "Mérito",
      [RecognitionType.Transfer]: "Transferência",
      [RecognitionType.SalaryChange]: "Alteração salarial",
    },
    error: {
      internalServerError:
        "Ocorreu um problema no sistema, por favor tente novamente mais tarde.",
    },
  },
}
